import Vue from "vue"
import Router from "vue-router"
import { store } from "./store/store"
import axios from "./plugins/axios"
import Databases from "@/views/databases/Databases.vue"
Vue.use(Router)

//console.log("router config.json ", Vue.prototype.$config)
//console.log("router url ", process.env.BASE_URL)
let router
if (Vue.prototype.$config.type == "admin") {
  //console.log("router admin ")
  router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
      {
        path: "/autentificare",
        component: () => import("@/views/pages/Index"),
        children: [
          {
            name: "Login",
            path: "",
            component: () => import("@/views/pages/Login")
          }
        ]
      },
      {
        path: "/",
        component: () => import("@/views/dashboard/Index"),
        children: [
          {
            name: "Home",
            meta: { title: "Home" },
            path: "",
            component: () => import("@/views/dashboard/Dashboard")
          }
        ]
      },
      {
        path: "/contul-meu",
        component: () => import("@/views/dashboard/Index"),
        children: [
          {
            name: "Profile",
            meta: { title: "Contul meu" },
            path: "",
            component: () => import("@/views/account/Profile.vue")
          }
        ]
      },
      {
        path: "/404",
        component: () => import("@/views/pages/Index404"),
        children: [
          {
            name: "404 Error",
            path: "",
            component: () => import("@/views/pages/Error")
          }
        ]
      }
    ]
  })
} else if (Vue.prototype.$config.type == "catalog") {
  console.log("Vue.prototype.$config.cat ", Vue.prototype.$config.cat)
  //console.log("Vue.prototype.$config.cat ", Vue.prototype.$config.baseURL)
  if (Vue.prototype.$config.cat == "nvd") {
    router = new Router({
      mode: "history",
      base: Vue.prototype.$config.baseURL,
      routes: [
        {
          path: "/",
          component: () => import("@/views/nvd/Index"),
          children: [
            {
              name: "NVD",
              meta: { title: "NVD" },
              path: "",
              component: () => import("@/views/nvd/pages/Home")
            },
            {
              name: "Vendors",
              meta: { title: "Vendors" },
              path: "vendors",
              component: () => import("@/views/nvd/pages/Vendors")
            },
            {
              name: "Products",
              meta: { title: "Products" },
              path: "product-list",
              component: () => import("@/views/nvd/pages/Products")
            },
            {
              name: "VendorProducts",
              meta: { title: "Products" },
              path: "product-list/vendor-*",
              component: () => import("@/views/nvd/pages/Products")
            },
            {
              name: "SearchVersion",
              meta: { title: "Version search" },
              path: "version-search",
              component: () => import("@/views/nvd/pages/SearchVersion")
            },
            {
              name: "VulnerabilitiesSearch",
              meta: { title: "Vulnerabilities search" },
              path: "vulnerabilities-search",
              component: () => import("@/views/nvd/pages/Vulnerabilities")
            },
            {
              name: "VulnerabilitiesVendorEdition",
              meta: { title: "Vulnerabilities Vendor Product Edition" },
              path: "vulnerabilities-search/vendor-*/product-*/language-*/update-*/edition-*",
              component: () => import("@/views/nvd/pages/Vulnerabilities")
            },
            {
              name: "VulnerabilitiesVendorProduct",
              meta: { title: "Vulnerabilities Vendor Product" },
              path: "vulnerabilities-search/vendor-*/product-*",
              component: () => import("@/views/nvd/pages/Vulnerabilities")
            },
            {
              name: "VulnerabilitiesVendor",
              meta: { title: "Vulnerabilities Vendor" },
              path: "vulnerabilities-search/vendor-*",
              component: () => import("@/views/nvd/pages/Vulnerabilities")
            },
            {
              name: "VulnerabilitiesProduct",
              meta: { title: "Vulnerabilities Product" },
              path: "vulnerabilities-search/product-*",
              component: () => import("@/views/nvd/pages/Vulnerabilities")
            },
            {
              name: "VulnerabilitiesMonth",
              meta: { title: "Vulnerabilities Month" },
              path: "vulnerabilities-search/year-*/month-*",
              component: () => import("@/views/nvd/pages/Vulnerabilities")
            },
            {
              name: "VulnerabilitiesByType",
              meta: { title: "Vulnerabilities by type" },
              path: "vulnerabilities-by-type",
              component: () => import("@/views/nvd/pages/VulnerabilitiesByType")
            },
            {
              name: "VulnerabilitiesByDate",
              meta: { title: "Vulnerabilities by date" },
              path: "browse-by-date",
              component: () => import("@/views/nvd/pages/VulnerabilitiesByDate")
            },
            {
              name: "MonthVulnerabilities",
              meta: { title: "Month vulnerabilities" },
              path: "vulnerability-list/year-*/month-*",
              component: () => import("@/views/nvd/pages/MonthVulnerabilities")
            },
            {
              name: "YearVulnerabilities",
              meta: { title: "Year vulnerabilities" },
              path: "vulnerability-list/year-*",
              component: () => import("@/views/nvd/pages/MonthVulnerabilities")
            },
            {
              name: "ApplicationsBugs",
              meta: { title: "Applications bugs" },
              path: "software-bugs",
              component: () => import("@/views/nvd/pages/ApplicationsBugs")
            },
            {
              name: "ApplicationsBug",
              meta: { title: "Applications bug" },
              path: "software-bugs/*",
              component: () => import("@/views/nvd/pages/ApplicationsBug")
            },
            {
              name: "RuntimeData",
              meta: { title: "Sensors data" },
              path: "runtime-data/sensors",
              component: () => import("@/views/nvd/pages/RuntimeData")
            },
            {
              name: "Exploits",
              meta: { title: "Exploits" },
              path: "exploits",
              component: () => import("@/views/nvd/pages/Exploits")
            },
            {
              name: "Components",
              meta: { title: "Components" },
              path: "components",
              component: () => import("@/views/nvd/pages/Components")
            },
            {
              name: "UseCaseProfiles",
              meta: { title: "Use Case Profiles" },
              path: "use-case-profiles",
              component: () => import("@/views/nvd/pages/UseCaseProfiles")
            },
            {
              name: "SensorsList",
              meta: { title: "Sensors list" },
              path: "sensors",
              component: () => import("@/views/nvd/pages/SensorsList")
            },
            {
              name: "FailuresList",
              meta: { title: "Failures list" },
              path: "failures",
              component: () => import("@/views/nvd/pages/FailuresList")
            },
            {
              name: "FailuresCases",
              meta: { title: "Failures use cases" },
              path: "runtime-data/failures",
              component: () => import("@/views/nvd/pages/FailuresCases")
            },
            {
              name: "MUDFiles",
              meta: { title: "MUD files" },
              path: "/mud-files",
              component: () => import("@/views/nvd/pages/MUDFiles")
            },
            {
              name: "CWEs",
              meta: { title: "CWE list" },
              path: "cwe",
              component: () => import("@/views/nvd/pages/CWEs")
            },
            {
              name: "CWE",
              meta: { title: "CWE" },
              path: "cwe/*",
              component: () => import("@/views/nvd/pages/CWE")
            },
            {
              name: "CVE",
              meta: { title: "CVE" },
              path: "cve",
              component: () => import("@/views/nvd/pages/CVE")
            },
            {
              name: "CVE",
              meta: { title: "CVE" },
              path: "cve/*",
              component: () => import("@/views/nvd/pages/CVE")
            }
          ]
        },
        {
          path: "*",
          component: () => import("@/views/nvd/Index"),
          children: [
            {
              name: "404 Error",
              path: "",
              component: () => import("@/views/nvd/pages/Error")
            }
          ]
        }
      ]
    })
  } else if (Vue.prototype.$config.cat == "infuni") {
    router = new Router({
      mode: "history",
      base: Vue.prototype.$config.baseURL,
      routes: [
        {
          path: "/",
          component: () => import("@/views/infuni/Index"),
          children: [
            {
              name: "INFUNI",
              meta: { title: "INFUNI" },
              path: "",
              component: () => import("@/views/infuni/pages/Home")
            },
            {
              name: "UniversityKPI",
              meta: { title: "University KPI" },
              path: "university-kpi",
              component: () => import("@/views/infuni/pages/UniversityKPI")
            },
            {
              name: "ConferencesEvents",
              meta: { title: "Conferences events" },
              path: "conferences-events",
              component: () => import("@/views/infuni/pages/ConferencesEvents")
            },
            {
              name: "ConferencesEvent",
              meta: { title: "Conferences event" },
              path: "conferences-events/id-*",
              component: () => import("@/views/infuni/pages/ConferencesEvent")
            },
            {
              name: "DidacticResources",
              meta: { title: "Didactic resources" },
              path: "didactic-resources",
              component: () => import("@/views/infuni/pages/DidacticResources")
            },
            {
              name: "DidacticResource",
              meta: { title: "Didactic resource" },
              path: "didactic-resources/id-*",
              component: () => import("@/views/infuni/pages/DidacticResource")
            },
            {
              name: "GraduateSyllabi",
              meta: { title: "Graduate syllabi" },
              path: "graduate-syllabi",
              component: () => import("@/views/infuni/pages/GraduateSyllabi")
            },
            {
              name: "Graduate",
              meta: { title: "Graduate syllabi" },
              path: "graduate-syllabi/id-*",
              component: () => import("@/views/infuni/pages/Graduate")
            },
            {
              name: "Scopus",
              meta: { title: "Scopus" },
              path: "scopus",
              component: () => import("@/views/infuni/pages/Scopus")
            },
            {
              name: "ScopusPublisher",
              meta: { title: "Scopus publisher" },
              path: "scopus/id-*",
              component: () => import("@/views/infuni/pages/ScopusPublisher")
            },
            {
              name: "Academics",
              meta: { title: "Academics" },
              path: "academics",
              component: () => import("@/views/infuni/pages/Academics")
            },
            {
              name: "ScopusPublications",
              meta: { title: "Scopus publications" },
              path: "scopus-publications",
              component: () => import("@/views/infuni/pages/ScopusPublications")
            },
            {
              name: "ScopusPublication",
              meta: { title: "Scopus publication" },
              path: "scopus-publications/id-*",
              component: () => import("@/views/infuni/pages/ScopusPublication")
            },
            {
              name: "AcademicsEmployee",
              meta: { title: "Academics employee" },
              path: "academics/id-*",
              component: () => import("@/views/infuni/pages/AcademicsEmployee")
            },
            {
              name: "Scientometrics",
              meta: { title: "Scientometrics" },
              path: "scientometrics",
              component: () => import("@/views/infuni/pages/Scientometrics")
            },
            {
              name: "ScientometricsJournal",
              meta: { title: "Scientometrics journal" },
              path: "scientometrics/issn-*",
              component: () => import("@/views/infuni/pages/ScientometricsJournal")
            },
            {
              name: "Publons",
              meta: { title: "Publons" },
              path: "publons",
              component: () => import("@/views/infuni/pages/Publons")
            },
            {
              name: "PublonsPublisher",
              meta: { title: "Publons publisher" },
              path: "publons/id-*",
              component: () => import("@/views/infuni/pages/PublonsPublisher")
            }
          ]
        },
        {
          path: "*",
          component: () => import("@/views/infuni/Index"),
          children: [
            {
              name: "404 Error",
              path: "",
              component: () => import("@/views/infuni/pages/Error")
            }
          ]
        }
      ]
    })
  } else {
    router = new Router({
      mode: "history",
      base: Vue.prototype.$config.baseURL,
      routes: [
        {
          path: "/",
          component: () => import("@/views/catalog/Index"),
          children: [
            {
              name: "eBibliophil",
              meta: { title: "eBibliophil" },
              path: "",
              component: () => import("@/views/catalog/pages/Home")
            },
            {
              name: "Autentificare",
              meta: { title: "Autentificare" },
              path: "autentificare",
              component: () => import("@/views/catalog/pages/Autentificare")
            },
            {
              name: "Inregistrare",
              meta: { title: "Înregistrare" },
              path: "inregistrare",
              component: () => import("@/views/catalog/pages/Inregistrare")
            },
            {
              name: "Resetare",
              meta: { title: "Resetare parolă" },
              path: "resetare-parola",
              component: () => import("@/views/catalog/pages/Resetare")
            },
            {
              name: "Circulatie",
              meta: { title: "Circulație" },
              path: "cont/circulatie",
              component: () => import("@/views/catalog/pages/cont/Circulatie")
            },
            {
              name: "Favorite",
              meta: { title: "Favorite" },
              path: "cont/favorite",
              component: () => import("@/views/catalog/pages/cont/Favorites")
            },
            {
              name: "Setari",
              meta: { title: "Setări" },
              path: "cont/setari",
              component: () => import("@/views/catalog/pages/cont/Setari")
            },
            {
              name: "Monografii",
              meta: { title: "Monografii" },
              path: "mon",
              component: () => import("@/views/catalog/pages/Monografii")
            },
            {
              name: "CategoriaMon",
              meta: { title: "Monografii" },
              path: "mon/cat-*",
              component: () => import("@/views/catalog/pages/Monografii")
            },
            {
              name: "Monografie",
              meta: { title: "Monografie" },
              path: "mon/*",
              component: () => import("@/views/catalog/pages/Monografie")
            },
            {
              name: "CautareGenerala",
              meta: { title: "Căutare generală" },
              path: "cautare",
              component: () => import("@/views/catalog/pages/SearchGeneral")
            },
            {
              name: "CautareAvansata",
              meta: { title: "Căutare avansată" },
              path: "cautare-avansata",
              component: () => import("@/views/catalog/pages/SearchAdvanced")
            },
            {
              name: "Articole",
              meta: { title: "Autorități" },
              path: "art",
              component: () => import("@/views/catalog/pages/Articole")
            },
            {
              name: "CategoriaArt",
              meta: { title: "Articole" },
              path: "art/cat-*",
              component: () => import("@/views/catalog/pages/Articole")
            },
            {
              name: "Articol",
              meta: { title: "Articol" },
              path: "art/*",
              component: () => import("@/views/catalog/pages/Articol")
            },
            {
              name: "Autoritati",
              meta: { title: "Autorități" },
              path: "ath",
              component: () => import("@/views/catalog/pages/Autoritati")
            },
            {
              name: "CategoriaAth",
              meta: { title: "Autorități" },
              path: "ath/cat-*",
              component: () => import("@/views/catalog/pages/Autoritati")
            },
            {
              name: "Autoritate",
              meta: { title: "Autoritate" },
              path: "ath/*",
              component: () => import("@/views/catalog/pages/Autoritate")
            }
            // {
            //   name: "404Autoritate",
            //   meta: { title: "Autoritate" },
            //   path: "404",
            //   component: () => import("@/views/pages/Error")
            // }
          ]
        },
        {
          path: "*",
          component: () => import("@/views/catalog/Index"),
          children: [
            {
              name: "404 Error",
              path: "",
              component: () => import("@/views/pages/Error")
            }
          ]
        }
      ]
    })
  }
}
//*/
function getComponent(name) {
  let comp
  switch (name) {
    case "Home":
      comp = () => import("@/views/dashboard/Home")
      break
    case "Dashboard":
      comp = () => import("@/views/dashboard/Dashboard")
      break
    case "Flow":
      comp = () => import("@/views/flow/Flow")
      break
    case "Catalog":
      comp = () => import("@/views/catalog/pages/Monografii")
      break
    case "SearchAdvanced":
      comp = () => import("@/views/catalog/pages/SearchAdvanced")
      break
    case "SearchGeneral":
      comp = () => import("@/views/catalog/pages/SearchGeneral")
      break
    case "Corrector":
      comp = () => import("@/views/dashboard/pages/Corrector")
      break
    case "EmptyFields":
      comp = () => import("@/views/dashboard/pages/EmptyFields")
      break
    case "DuplicateFields":
      comp = () => import("@/views/dashboard/pages/DuplicateFields")
      break
    case "LibrarySettings":
      comp = () => import("@/views/dashboard/pages/LibrarySettings")
      break
    case "CompareRecords":
      comp = () => import("@/views/dashboard/pages/CompareRecords")
      break
    case "ImportEbibliophil":
      comp = () => import("@/views/dashboard/template/ImportEbibliophil")
      break
    case "ImportEbibliophil4":
      comp = () => import("@/views/dashboard/template/ImportEbibliophil4")
      break
    case "ImportCSV":
      comp = () => import("@/components/containers/ImportCSV")
      break
    case "Databases":
      comp = Vue.extend({ ...Databases })
      //comp = import Databases from "@/views/databases/Databases.vue"
      //comp = () => import("@/views/databases/Databases.vue")
      //comp = Vue.component("mydb" + Date.now(), { ...Databases })
      break
    case "Profil":
      comp = () => import("@/views/account/Profile.vue")
      break
    case "SettingsSchema":
      comp = () => import("@/views/settings/SettingsSchema.vue")
      break
    case "SettingsForms":
      comp = () => import("@/views/settings/SettingsForms.vue")
      break
    case "SettingsTM":
      comp = () => import("@/views/settings/SettingsTM.vue")
      break
    case "SettingsMenu":
      comp = () => import("@/views/settings/SettingsMenu.vue")
      break
    case "SettingsDictionary":
      comp = () => import("@/views/settings/SettingsDictionary.vue")
      break
    case "SettingsCompanies":
      comp = () => import("@/views/settings/SettingsCompanies.vue")
      break
    case "SettingsUsers":
      comp = () => import("@/views/settings/SettingsUsers.vue")
      break
    case "TemplateCompany":
      comp = () => import("@/views/dashboard/template/Company")
      break
    case "TemplateQuotation":
      comp = () => import("@/views/dashboard/template/Admin")
      break
    case "TemplateProducts":
      comp = () => import("@/views/dashboard/template/Inventories")
      break
    case "TemplateReaders":
      comp = () => import("@/views/dashboard/template/Readers")
      break
    case "TemplateCompanies":
      comp = () => import("@/views/dashboard/template/Companies")
      break
    case "TemplateRMF":
      comp = () => import("@/views/dashboard/template/RMF")
      break
    case "TemplateMedia":
      comp = () => import("@/views/dashboard/template/Media")
      break
    case "TemplateAutoritati":
      comp = () => import("@/views/dashboard/template/Locatie")
      break
    case "TemplateEvenimente":
      comp = () => import("@/views/dashboard/template/Evenimente")
      break
    case "TemplateArticole":
      comp = () => import("@/views/dashboard/template/Articole")
      break
    case "TemplateServices":
      comp = () => import("@/views/dashboard/template/MudFiles")
      break
    case "TemplateProvider":
      comp = () => import("@/views/dashboard/template/Provider")
      break
    case "TemplateMacheta":
      comp = () => import("@/views/dashboard/template/Monografii")
      break
    case "TemplateDocuments":
      comp = () => import("@/views/dashboard/template/Documents")
      break
    case "Documents":
      comp = () => import("@/views/documents/Documents.vue")
      break
    case "Templates":
      comp = () => import("@/views/documents/Templates.vue")
      break
    case "ClientsDocuments":
      comp = () => import("@/views/documents/ClientsDocuments.vue")
      break
    case "RapoarteInventare":
      comp = () => import("@/views/dashboard/reports/RapoarteInventare.vue")
      break
    case "Scientometrics":
      comp = () => import("@/views/infuni/pages/tools/Scientometrics.vue")
      break
    case "Academics":
      comp = () => import("@/views/infuni/pages/tools/Academics.vue")
      break
    case "AcademicsTools":
      comp = () => import("@/views/infuni/pages/academics/Tools.vue")
      break
    case "BiecoTools":
      comp = () => import("@/views/nvd/pages/Tools.vue")
      break
  }
  return comp
}

var menuDisabled = {}

function setMenuDisabled() {
  //console.log()
  if (store.getters.accountMenuList) {
    store.getters.accountMenuList.map(men => {
      //console.log("men1 ", men.path)
      //console.log("men1 ", men.enabled)
      if (!men.enabled) {
        menuDisabled[men.path] = true
      }
      if (men.items) {
        men.items.map(sub => {
          if (!sub.enabled) {
            menuDisabled[men.path + "/" + sub.path] = true
          }
        })
      }
    })
  }
}

function appTypeChanged(appType) {
  oldappType = appType
  return new Promise((resolve, reject) => {
    // console.log(
    //   "router get menu ",
    //   'app_menu?where={"owner.app_type":"' + appType + '","owner.user_type":"' + store.getters.accountLevel + '"}'
    // )
    //setMenuDisabled()
    axios
      .get(
        'app_menu?where={"owner.app_type":"' +
          appType +
          '","menu_type":"' +
          store.getters.accountMenu +
          '","owner.user_type":"' +
          store.getters.accountLevel +
          '"}'
      )
      // .get('app_menu?where={"name":"Super Admin"}')
      .then(response => {
        store.commit("accountMenuDefault", response.data._items[0].content)
        response.data._items[0].content.map(itm => {
          //console.log("menud1 ", itm.path)
          //console.log("menud2 ", menuDisabled[itm.path])
          if (!menuDisabled[itm.path]) {
            const comp = getComponent(itm.component)
            itm.name = itm.component + itm.path
            const children = []
            if (itm.items) {
              itm.items.map(child => {
                //console.log("child items ", child.items)
                if (child.items) {
                  child.items.map(subc => {
                    const childc = getComponent(subc.component)
                    subc.component = subc.component + subc.path
                    children.push({
                      path: child.path + "/" + subc.path,
                      name: subc.component,
                      meta: {
                        title: subc.title,
                        icon: subc.icon,
                        collection: subc.collection,
                        where: subc.where,
                        view: subc.view,
                        table: subc.table
                      },
                      component: childc
                    })
                  })
                } else {
                  if (!menuDisabled[itm.path + "/" + child.path]) {
                    const childc = getComponent(child.component)
                    child.component = child.component + child.path
                    children.push({
                      path: itm.path + "/" + child.path,
                      name: child.component,
                      meta: {
                        title: child.title,
                        icon: child.icon,
                        collection: child.collection,
                        where: child.where,
                        view: child.view,
                        table: child.table
                      },
                      component: childc
                    })
                  }
                }
              })
            } else {
              children.push({
                path: itm.path,
                name: itm.name,
                meta: {
                  title: itm.title,
                  icon: itm.icon,
                  collection: itm.collection,
                  where: itm.where,
                  view: itm.view,
                  table: itm.table
                },
                component: comp
              })
            }
            const route = {
              path: "/",
              component: () => import("@/views/dashboard/Index"),
              meta: { title: itm.title },
              children: children
            }
            //console.log("rpute ", route)
            router.addRoute(route)
            //router.addRoutes([route])
          }
        })

        /*
{
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error'),
        },
      ],
    },
        */

        resolve()
      })
      .catch(() => {
        //console.log("error ", error)
        reject(new Error("error"))
      })
  })
}

// function hasQueryParams(route) {
//   return !!Object.keys(route.query).length
// }

var oldappType = ""
var menuLoaded = false
// router gards
router.beforeEach((to, from, next) => {
  const appType = store.getters.appType
  //console.log("router apptype " + appType + " - " + oldappType)
  //console.log(typeof appType)
  //console.log(typeof oldappType)
  //console.log(appType !== oldappType)
  //console.log("beforeEach to ", to)
  //console.log("beforeEach to ", hasQueryParams(to))
  //console.log("beforeEach from ", from)
  //console.log("beforeEach from ", hasQueryParams(from))
  if ((appType && oldappType !== appType) || !menuLoaded) {
    //console.log("diferitttttttttttttttttt")
    axios.defaults.headers.common.Authorization = localStorage.sessionId
    if (Vue.prototype.$config.type == "catalog") {
      oldappType = appType
      menuLoaded = true
      //console.log("diferitttttttttttttttttt")
      //store.dispatch("getLibraryData")
      store.dispatch("getappSchema")
      if (appType == "ebib") {
        store.dispatch("getconfPrefixes")
      }
      next()
      // if (to.matched.length == 0) {
      //   //next("/404")
      //   next({ path: to.path, query: to.query })
      // } else {
      //   next({ path: to.path, query: to.query })
      // }
    } else if (Vue.prototype.$config.type == "admin") {
      //store.dispatch("getLibraryData")
      if (appType) {
        if (appType == "ebib") {
          store.dispatch("getconfPrefixes")
        }
        store.dispatch("getappView")
        store.dispatch("getdictionaries")
        store.dispatch("getMyDictionaries")
        store.dispatch("getappSchema")
        //console.log("appTypeChanged ", appType)
        setMenuDisabled()
        appTypeChanged(appType)
          .then(() => {
            menuLoaded = true
            // if (to.matched.length == 0) {
            //   next("/404")
            // } else {
            //   next({ path: to.path, query: to.query })
            // }
            next({ path: to.path, query: to.query })
            //next(to.path)
            //next()
          })
          .catch(() => {
            //console.log("router apptype error" + appType + " - " + oldappType)
            next()
          })
      } else {
        if (to.matched.length == 0) {
          next("/autentificare")
        } else {
          next()
        }
      }
    }
  } else {
    //console.log("router apptype else" + appType + " - " + oldappType)
    if (!appType) {
      oldappType = ""
    }
    if (to.matched.length == 0 && Vue.prototype.$config.type != "catalog") {
      next("/404")
      //next()
    }
    next()
  }
})

store.subscribe(mutation => {
  if (mutation.type === "appType") {
    //console.log("appppppppppppppppppppppp")
    if (oldappType && mutation.payload && oldappType != mutation.payload) {
      router.go()
    }
  } // appTypeChanged(mutation.payload)
})

export default router
