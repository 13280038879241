<template>
  <v-dialog v-model="show" persistent scrollable max-width="1200px">
    <v-card>
      <v-card-title class="py-2 pr-2 d-flex flex-nowrap">
        <span class="text-truncate">
          {{ $t("Preview:") + " " + title }}
        </span>
        <v-spacer />
        <v-icon color="red" large @click="show = false">
          mdi-close
        </v-icon>
      </v-card-title>

      <v-card-text class="pa-1">
        <component :is="previewForm" ref="preview" :record-id="recordId" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  components: {},
  props: {
    collection: { type: String, default: "" }
  },
  data: () => ({
    show: false,
    recordId: "",
    title: ""
  }),
  computed: {
    previewForm: function() {
      if (this.collection == "nvd_cve") {
        return () => import("../nvd/pages/CVE.vue")
      } else if (this.collection == "nvd_cwe") {
        return () => import("../nvd/pages/CWE.vue")
      } else if (this.collection == "infuni_scientometrics") {
        return () => import("../infuni/pages/ScientometricsJournal.vue")
      }
      return null
    }
  },
  mounted() {
    this.$log("mounted preview " + this.collection)
    this.$emit("mounted")
  },
  methods: {
    reload(e) {
      this.$log("reload preview")
      //this.show = false
      this.$emit("reload", e)
    },
    previewData(id, title) {
      this.$log("previewData ", id)
      this.show = true
      this.title = title
      this.recordId = id
    }
  }
}
</script>
<style scoped></style>
