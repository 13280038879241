<template>
  <div>
    <!-- <htm-view
      id="htmView"
      ref="htmView"
      name="hview"
      :record="record"
      :record-view="recordView"
      :dictionary="dictionary"
      :schema="schemaContent"
      :company-id="companyId"
      :prefix="prefix"
      @openSearch="openSearch"
      @createAndEdit="createAndEdit"
    /> -->
    <component
      :is="htmView"
      id="htmView"
      ref="htmView"
      name="hview"
      :collection="collection"
      :record="record"
      :record-view="recordView"
      :dictionary="dictionary"
      :schema="schemaContent"
      :company-id="companyId"
      :prefix="prefix"
      @openSearch="openSearch"
      @createAndEdit="createAndEdit"
      @sheet="$emit('sheet', $event)"
    />
    <!-- <search-link ref="searchLink" @select="setLinkValue" /> -->
  </div>
</template>
<script>
import { EventBus } from "../../EventBus"
// import md5 from "js-md5"
// import SearchLink from "../../components/containers/SearchLink"
import "quill/dist/quill.core.css"
import "quill/dist/quill.snow.css"
import "quill/dist/quill.bubble.css"
import { quillEditor } from "vue-quill-editor"
import SDateTime from "../../components/inputs/DateTime"
import SAutocomplete from "../../components/inputs/Autocomplete"
import AddImage from "../../components/containers/AddImage"
import AddFile from "../../components/containers/AddFile"
import AddImageMultiple from "../../components/containers/AddImageMultiple"
import ScreamPdf from "../../components/containers/ScreamPdf"
import LinkFields from "../../components/containers/LinkFields"
import RecordHistory from "../../components/containers/RecordHistory"
import VTreeview from "vuetify/lib/components/VTreeview/VTreeview"
import IsbdInfo from "../../components/containers/IsbdInfo"
import ManageResource from "../../components/containers/ManageResource"
// import pdf from "pdfvuer"
import axios from "../../plugins/axios"
import manageRecord from "../../util/manage-record"
// import VueMask from "v-mask"

//import Vue from "vue"

//let idGlobal = 50

//const HtmView = new Vue({
//el: "#htmView",

export default {
  components: {},
  data: () => ({
    htmView: null,
    idGlobal: 50,
    schemaContent: {},
    schema: {},
    collection: "",
    comp: "div",
    recordId: "",
    prefix: "",
    dictionary: {},
    recordView: {},
    record: {},
    searchId: null,
    selectId: null
  }),
  computed: {
    companyId() {
      return this.$store.getters.companyId
    }
  },
  created() {
    EventBus.$on("search-link-selected", search => {
      //this.$log("search-link-selected1 ", search)
      //this.$log("search-link-selected2 ", this.searchId)
      if (this.searchId == search.id) {
        this.$log("setlink ", this.$refs.htmView)
        this.$log("setlink ", search)
        this.$refs.htmView.setLinkValue(search.value)
      }
    })
    EventBus.$on("dictionary-reload", dict => {
      this.$log("dictionary-reload ", dict)
      if (this.selectId == dict.id) {
        this.getDictionaryValues("all." + dict.dictionary)
        if (dict.select) {
          this.$refs.htmView.setLinkValue(dict.select)
        }
      }
    })
  },
  methods: {
    ...manageRecord,
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    },
    cloneKey(keys, clone) {
      return (clone[keys] = {})
    },
    getRecord() {
      // const rec = { ...this.record }
      this.$log("rec01 ", this.record)
      const rec = JSON.parse(JSON.stringify(this.record))
      this.$log("rec02 ", rec)
      for (const itm in this.record) {
        if (this.record[itm] instanceof File) {
          rec[itm] = this.record[itm]
        }
      }
      this.getCleanRecord(rec, "", this.schemaContent)
      return rec
    },
    // getdictionary(dic) {
    //   return this.$store.getters.dictionary(dic)
    // },
    getdictionary(dic) {
      return new Promise((resolve, reject) => {
        if (this.$store.getters.dictionary("all_" + dic)) {
          resolve(this.$store.getters.dictionary("all_" + dic))
        } else {
          axios
            .get('app_dictionary?where={"value":"' + dic + '"}')
            .then(response => {
              if (response.data._items[0]) {
                this.$store.commit("dictionary", { name: "all_" + dic, values: response.data._items[0].content })
                resolve(response.data._items[0].content)
              }
            })
            .catch(err => {
              this.$log("errrrrrr ", err)
              reject(new Error("Dictionary not found"))
            })
        }
      })
    },
    getDictionaryValues(dic) {
      //this.$log("getDictionaryValues(itm) ", dic)
      const dictionary = dic.split(".")
      if (dictionary[0] == "all") {
        //axios.get('app_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
        //this.$set(this.dictionary, dictionary[1], this.getdictionary("all_" + dictionary[1]))
        this.getdictionary(dictionary[1]).then(res => {
          this.$set(this.dictionary, dictionary[1], res)
        })
        // this.dictionary[dictionary[1]] = response.data._items[0].content
        //})
      } else if (dictionary[0] == "system") {
        axios.get('app_system_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
          this.$set(this.dictionary, dictionary[1], response.data._items[0].content)
          // this.dictionary[dictionary[1]] = response.data._items[0].content
        })
      }
    },
    copyData(fields) {
      //this.$log("copyData ", fields)
      this.$set(this.record, "_id", undefined)
      fields.map(fld => {
        this.$set(this.record.fields, fld, JSON.parse(JSON.stringify(this.recordView.fields[fld])))
      })
    },
    showData(recordData, scm, con) {
      this.schema = scm.content[0]
      const arr = scm.collection.split("_")
      this.collection = scm.collection
      this.prefix = arr[1]
      this.$log("show data recordData ", recordData)
      this.$log("show data schema ", scm)
      //this.$log("show data con ", con)
      //this.$log("show data this.prefix ", this.prefix)
      // this.list = schema
      // axios.get("ebib_marc_link_versions").then(ver => {
      //   this.$log("versions ", ver)
      // })
      this.schemaContent = con
      this.generateList(con, this.recordView)
      //this.$log("this.recordView", JSON.stringify(this.recordView))
      this.record = recordData
      // this.recordView = this.cloneObject({ children: [...this.list] }, {})
      const dic = JSON.parse(JSON.stringify(this.recordView))
      Object.assign(dic, JSON.parse(JSON.stringify(this.record)))
      this.record = { ...dic }
      this.generateRecord(this.record, { ...this.recordView })
      //this.$log("this.recordView", this.recordView)
      //this.$log("this.record", this.record)
      //this.$log("this.schemaContent ", schema)
      //this.$log("this.schemaContent ", con.app_type)
      this.$log("this.schemaContent1 ", this.schemaContent)
      //this.$log("this.record", JSON.stringify(this.recordView))
      for (const v in this.dictionary) {
        this.dictionary[v] = undefined
      }

      if (this.schema.dictionary) {
        this.schema.dictionary.map(itm => this.getDictionaryValues(itm))
      }
      //Vue.use(VueMask)
      // Vue.component("htm-view", {
      this.htmView = {
        components: {
          SDateTime,
          AddImage,
          AddFile,
          AddImageMultiple,
          SAutocomplete,
          LinkFields,
          ScreamPdf,
          VTreeview,
          IsbdInfo,
          RecordHistory,
          ManageResource,
          quillEditor
        },
        props: {
          companyId: {
            type: String,
            default: ""
          },
          record: {
            type: Object,
            default: () => {}
          },
          recordView: {
            type: Object,
            default: () => {}
          },
          schema: {
            type: Object,
            default: () => {}
          },
          prefix: {
            type: String,
            default: ""
          },
          collection: {
            type: String,
            default: ""
          },
          dictionary: {
            type: Object,
            default: () => {}
          }
        },
        data: () => ({
          md5: require("js-md5"),
          render: 1,
          idGlobal: 150,
          linkArray: null,
          linkPrefix: null,
          linkField: null,
          linkSubfield: null,
          linkIndex: null,
          fileName: "",
          file: {},
          urlImg: null,
          dense: true,
          hided: false,
          c: 10,
          editorOption: {
            modules: {
              // toolbar: [
              //   ["bold", "italic", "underline", "strike"],
              //   ["blockquote", "code-block"],
              //   [{ list: "ordered" }, { list: "bullet" }],
              //   [{ script: "sub" }, { script: "super" }],
              //   [{ indent: "-1" }, { indent: "+1" }],
              //   [{ header: [1, 2, 3, 4, 5, 6, false] }],
              //   [{ color: [] }, { background: [] }],
              //   [{ align: [] }],
              //   ["clean"]
              // ]
              toolbar: [
                ["bold", "italic", "underline", "strike"],
                ["blockquote", "code-block"],
                [{ header: 1 }, { header: 2 }],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ direction: "rtl" }],
                [{ size: ["small", false, "large", "huge"] }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ font: [] }],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                ["clean"],
                ["link", "image", "video"]
              ],
              history: {
                delay: 2000,
                maxStack: 500,
                userOnly: true
              }
            },
            placeholder: ""
          }
        }),
        computed: {
          settingsTipUtilizator() {
            const items = []
            if (this.record.config) {
              if (this.record.config.library) {
                for (const gestiune in this.record.config.library.loan) {
                  for (const tipUtilizator in this.record.config.library.loan[gestiune]) {
                    if (items.indexOf(tipUtilizator) < 0) {
                      items.push(tipUtilizator)
                    }
                  }
                }
              }
            }
            return items
          },
          settingsRegim() {
            const items = []
            if (this.record.config) {
              if (this.record.config.library) {
                for (const gestiune in this.record.config.library.loan) {
                  for (const tipUtilizator in this.record.config.library.loan[gestiune]) {
                    for (const regim in this.record.config.library.loan[gestiune][tipUtilizator]) {
                      if (items.indexOf(regim) < 0) {
                        items.push(regim)
                      }
                    }
                  }
                }
              }
            }
            return items
          },
          settingsGestiuni() {
            const items = []
            for (const gestiune in this.record.config.library.loan) {
              items.push(gestiune)
            }
            return items
          },
          bibLoan() {
            this.$log("bibLoan ", this.record.config.library.loan)
            const items = []
            if (this.record.config) {
              if (this.record.config.library) {
                let i = 1
                for (const gestiune in this.record.config.library.loan) {
                  if (this.record.config.library.loan[gestiune]) {
                    const ges = { id: i++, val: "gestiune", name: gestiune, children: [] }
                    for (const tipUtilizator in this.record.config.library.loan[gestiune]) {
                      if (this.record.config.library.loan[gestiune][tipUtilizator]) {
                        const tip = { id: i++, val: "tip", gestiune, name: tipUtilizator, children: [] }
                        for (const regim in this.record.config.library.loan[gestiune][tipUtilizator]) {
                          if (this.record.config.library.loan[gestiune][tipUtilizator][regim]) {
                            const reg = {
                              id: i++,
                              val: "regim",
                              name: regim,
                              gestiune,
                              tipUtilizator,
                              children: [
                                {
                                  id: i++,
                                  val: "days",
                                  gestiune,
                                  tipUtilizator,
                                  regim,
                                  name: this.record.config.library.loan[gestiune][tipUtilizator][regim].days
                                },
                                {
                                  id: i++,
                                  val: "items",
                                  gestiune,
                                  tipUtilizator,
                                  regim,
                                  name: this.record.config.library.loan[gestiune][tipUtilizator][regim].items
                                }
                              ]
                            }
                            tip.children.push(reg)
                          }
                        }
                        ges.children.push(tip)
                      }
                    }
                    items.push(ges)
                  }
                }
              }
            }
            return items
          },
          userType() {
            return this.$store.getters.accountLevel
          },
          content_type() {
            if (this.record.content_type.substr(0, 5) == "image") {
              return "image"
            } else if (this.record.content_type.substr(0, 5) == "audio") {
              return "audio"
            } else if (this.record.content_type.substr(0, 5) == "video") {
              return "video"
            }
            return this.record.content_type
          },
          inputValidate() {
            const rule = []
            rule.push(v => !!v || this.$t("required"))
            return rule
          },
          baseURL() {
            return axios.defaults.baseURL
          },
          userMenuID() {
            return this.dictionary.userMenuID
          },
          userMenuEdit() {
            return this.record.menu
          }
        },
        watch: {
          userMenuEdit() {
            //this.$log("userMenuEdit ", this.record.menu)
          },
          userMenuID() {
            //this.$log("userMenuID changed ", this.dictionary.userMenuID)
            if (this.dictionary.userMenuID._id) {
              axios.get("app_menu/" + this.dictionary.userMenuID._id).then(response => {
                this.$log("menu response ", response)
                let i = 0
                response.data.content.map(men => {
                  men.id = i++
                  if (men.items) {
                    men.items.map(sub => {
                      sub.id = i++
                    })
                  }
                })
                this.$set(this.record, "menu", response.data.content)
              })
            }
          }
        },
        created() {
          //this.$log("creadet ------------- ", this.record)
          if (this.collection == "user") {
            axios
              .get(
                'app_menu/?where={"owner.user_type":{"$regex":"' +
                  this.record.settings.user_type +
                  '","$options":"i"},"menu_type":{"$regex":"' +
                  this.record.settings.menu_type +
                  '","$options":"i"}}'
              )
              .then(response => {
                //this.$log("menu response---- ", response)
                //this.$log("menu response---- ", this.record.menu)
                if (this.record.menu[0]) {
                } else {
                  let i = 0
                  response.data._items[0].content.map(men => {
                    men.id = i++
                    if (men.items) {
                      men.items.map(sub => {
                        sub.id = i++
                      })
                    }
                  })
                  this.$set(this.record, "menu", response.data._items[0].content)
                }
                // let i = 0
                // response.data.content.map(men => {
                //   men.id = i++
                //   if (men.items) {
                //     men.items.map(sub => {
                //       sub.id = i++
                //     })
                //   }
                // })
                // this.$set(this.record, "menu", response.data.content)
              })
          }
        },
        mounted() {
          //this.$log("mounted ------------- ", this.schema)
          //this.$log("mounted ------------- ", this.collection)
          // this["addFunc"] = new Function(eval("function addFunc(b){this.$log('ffffff ',b)}")
          // this.func = eval("function(a, b) { return a + b }")
          // eval("this.func=function(a, b) { return a + b+this.c }")
          // eval("var func = function (a, b) { return a + b; };")
        },
        methods: {
          addResource(res) {
            this.$log("addResource ", res)
            if (this.record.fields[res.field][0].link) {
              this.record.fields[res.field].push({
                link: res.link,
                ord: res.ord,
                a: [{ val: res.name }],
                c: [{ val: res.type }]
              })
            } else {
              this.$set(this.record.fields[res.field][0], "link", res.link)
              this.$set(this.record.fields[res.field][0], "ord", res.ord)
              this.$set(this.record.fields[res.field][0].a[0], "val", res.name)
              this.$set(this.record.fields[res.field][0].c[0], "val", res.type)
            }
            this.render++
          },
          delResource(res) {
            const i = this.record.fields[res.field].findIndex(itm => itm.link == res.link)
            if (i >= 0) {
              if (this.record.fields[res.field].length > 1) {
                this.record.fields[res.field].splice(i, 1)
              } else {
                this.record.fields[res.field].splice(0, 1, { link: "", a: [{ val: "" }], c: [{ val: "" }] })
              }
            }
            this.render++
          },
          chgResource(chg) {
            this.$log("chgResource", chg)
            chg.map(item => {
              if (item.link) {
                this.$set(
                  this.record.fields[item.field].find(itm => itm.link == item.link),
                  "ord",
                  item.ord
                )
                //this.$set(this.record.fields, item.field, this.record.fields[item.field])
              }
            })
            this.render++
            //this.$set(this.record, "render", this.render)
          },
          addsetGestiune(itm) {
            const rec = {}
            itm.children.map(tip => {
              rec[tip.name] = {}
              tip.children.map(regim => {
                rec[tip.name][regim.name] = { days: regim.children[0].name, items: regim.children[1].name }
              })
            })
            this.$set(this.record.config.library.loan, "Gestiune noua", rec)
            this.render++
          },
          delsetGestiune(itm) {
            this.$set(this.record.config.library.loan, itm.name, undefined)
            this.render++
          },
          chgsetGestiune(oldv, newv) {
            this.$set(this.record.config.library.loan, newv, this.record.config.library.loan[oldv])
            this.$set(this.record.config.library.loan, oldv, undefined)
            this.render++
          },
          addsetTipUtilizator(itm) {
            const rec = {}
            itm.children.map(regim => {
              rec[regim.name] = { days: regim.children[0].name, items: regim.children[1].name }
            })
            this.$set(this.record.config.library.loan[itm.gestiune], "Tip utilizator nou", rec)
            this.render++
          },
          delsetTipUtilizator(itm) {
            this.$set(this.record.config.library.loan[itm.gestiune], itm.name, undefined)
            this.render++
          },
          chgsetTipUtilizator(item, oldv, newv) {
            this.$set(
              this.record.config.library.loan[item.gestiune],
              newv,
              this.record.config.library.loan[item.gestiune][oldv]
            )
            this.$set(this.record.config.library.loan[item.gestiune], oldv, undefined)
            this.render++
          },
          addsetRegim(itm) {
            const rec = { days: itm.children[0].name, items: itm.children[1].name }
            this.$set(this.record.config.library.loan[itm.gestiune][itm.tipUtilizator], "Regim nou", rec)
            this.render++
          },
          delsetRegim(itm) {
            this.$set(this.record.config.library.loan[itm.gestiune][itm.tipUtilizator], itm.name, undefined)
            this.render++
          },
          chgsetRegim(item, oldv, newv) {
            this.$set(
              this.record.config.library.loan[item.gestiune][item.tipUtilizator],
              newv,
              this.record.config.library.loan[item.gestiune][item.tipUtilizator][oldv]
            )
            this.$set(this.record.config.library.loan[item.gestiune][item.tipUtilizator], oldv, undefined)
            this.render++
          },
          chgsetDays(item, oldv, newv) {
            this.$set(
              this.record.config.library.loan[item.gestiune][item.tipUtilizator][item.regim],
              "days",
              Number(newv)
            )
            this.render++
          },
          chgsetItems(item, oldv, newv) {
            this.$set(
              this.record.config.library.loan[item.gestiune][item.tipUtilizator][item.regim],
              "items",
              Number(newv)
            )
            this.render++
          },
          validateValue(fld, sub, val, items, field) {
            //this.$log("validateValue1 ", this.schema.fields.schema[fld].schema.schema[sub].schema.schema.val)
            const rule = []
            if (this.schema.fields.schema[fld]) {
              if (this.schema.fields.schema[fld].schema.schema[sub]) {
                const schema = this.schema.fields.schema[fld].schema.schema[sub].schema.schema.val
                //this.$log("validateValue2 ", fld + sub + " - " + schema.meta.mandatory)
                if (schema.regex) {
                  const reg = new RegExp(schema.regex)
                  if (schema.meta.regex_info) {
                    rule.push(v => !v || reg.test(v) || schema.meta.regex_info)
                  } else {
                    rule.push(v => !v || reg.test(v) || "Invalid")
                  }
                }
                if (schema.meta.mandatory == 1 || schema.required) {
                  rule.push(v => !!v || this.$t("required"))
                }
              }
            }
            if (val && items) {
              if (field) {
                if (!items.find(itm => itm[field] == val)) {
                  rule.push('Valoarea "' + val + '" nu se află in listă')
                }
              } else {
                if (items.indexOf(val) < 0) {
                  rule.push('Valoarea "' + val + '" nu se află in listă')
                }
              }
            }
            return rule
          },
          enabledReinscrie() {
            //this.$log("enabledReinscrie1 ", this.record.fields["505"][0].z[0].val)
            if (this.record.fields["505"][0].z[0].val) {
              const d = this.$store.getters.serverDate
              const arr = this.record.fields["505"][0].z[0].val.split(".")
              const d1 = new Date(arr[2] + "-" + arr[1] + "-" + arr[0])
              //this.$log("enabledReinscrie2 ", d1.getFullYear())
              //this.$log("enabledReinscrie3 ", d.getFullYear())
              if (d1.getFullYear() <= d.getFullYear()) {
                return true
              } else {
                return false
              }
            }
            return true
          },
          enabledVizeaza() {
            if (this.record.fields["505"][0].b[0].val) {
              const d = this.$store.getters.serverDate
              const arr = this.record.fields["505"][0].b[0].val.split(".")
              const d1 = new Date(arr[2] + "-" + arr[1] + "-" + arr[0])
              if (d1.getFullYear() == d.getFullYear()) {
                return false
              }
              if (this.record.fields["505"][0].e[0].val) {
                const arr2 = this.record.fields["505"][0].b[0].val.split(".")
                const d2 = new Date(arr2[2] + "-" + arr2[1] + "-" + arr2[0])
                if (d2.getFullYear() <= d.getFullYear()) {
                  return true
                }
                return false
              } else {
                return true
              }
            }
            return false
          },
          errorMessages(fld, sub, v, items, field) {
            if (this.prefix == "cln") {
              if (fld == "505" && sub == "b" && v) {
                const d = this.$store.getters.serverDate
                const arr = v.split(".")
                const d1 = new Date(arr[2] + "-" + arr[1] + "-" + arr[0])
                if ((d - d1) / (1000 * 60 * 60 * 24) > 365 * 5) {
                  return "Înscriere expirată"
                }
                return ""
              }
              if (fld == "505" && sub == "e" && v) {
                const d = this.$store.getters.serverDate
                const arr = v.split(".")
                const d1 = new Date(arr[2] + "-" + arr[1] + "-" + arr[0])
                if ((d - d1) / (1000 * 60 * 60 * 24) > 365) {
                  return "Vizare expirată"
                }
                return ""
              }
            }
            //this.$log("errorMessages1 ", v)
            //this.$log("errorMessages2 ", items)
            if (v && items) {
              if (field) {
                if (!items.find(itm => itm[field] == v)) {
                  return 'Valoarea "' + v + '" nu se află in listă'
                }
              } else {
                if (items.indexOf(v) < 0) {
                  return 'Valoarea "' + v + '" nu se află in listă'
                }
              }
            }
            return ""
          },
          rmfMask() {
            const year = [/[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/]
            const nr = [/[0-9]/, /[0-9]/, /[0-9]/]
            if (this.record.fields["200"][0].a[0].val.length > 8) {
              return [...year, "/", ...nr, "/", /(.*)/, /(.*)/, /(.*)/, /(.*)/, /(.*)/, /(.*)/]
            }
            return [...year, "/", ...nr]
          },
          invMask() {
            // this.$log("invmask ", this.record.fields["100"][0].a[0].val)
            const prefix = [/[a-zA-Z]/, /[a-zA-Z]/]
            const nr = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
            const sufix = [/[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/]
            if (this.record.fields["100"][0].a[0].val.length == 0) {
              // return [/[0-9a-zA-Z]/]
              return [...nr]
            } else if (this.record.fields["100"][0].a[0].val.length > 0) {
              if (Number.isInteger(Number(this.record.fields["100"][0].a[0].val.substr(0, 1)))) {
                if (this.record.fields["100"][0].a[0].val.length > 7) {
                  return [...nr, "/", ...sufix]
                } else {
                  // this.$log("nrrr")
                  return [...nr]
                }
              } else {
                if (this.record.fields["100"][0].a[0].val.length > 10) {
                  return [...prefix, "/", ...nr, "/", ...sufix]
                } else {
                  return [...prefix, "/", ...nr]
                }
              }
            }
          },
          checkDuplicate(pre, fld, sub, val) {
            if (val) {
              if (this.record._id) {
                axios
                  .post(
                    "marc/is_duplicated_fs_value_on_update/" +
                      pre +
                      "/" +
                      this.record._id +
                      "/" +
                      fld +
                      "/" +
                      sub +
                      "/" +
                      val
                  )
                  .then(response => {
                    this.$set(this.dictionary, fld + sub, response.data > 0)
                  })
              } else {
                axios.post("marc/is_duplicated_fs_value/" + pre + "/" + fld + "/" + sub + "/" + val).then(response => {
                  this.$set(this.dictionary, fld + sub, response.data > 0)
                })
              }
              this.$log("checkDuplicate ", this.record)
              //is_duplicated_fs_value_on_update
            } else {
              this.$set(this.dictionary, fld + sub, false)
            }
          },
          setDataNasteri(e) {
            //this.$log("setDataNasteri ", e)
            let dat = new Date()
            let sec = "19"
            if (e.length == 13) {
              switch (e.substr(0, 1)) {
                case "1":
                case "2":
                  sec = "19"
                  break
                case "3":
                case "4":
                  sec = "18"
                  break
                case "5":
                case "6":
                  sec = "20"
                  break
                case "7":
                case "8":
                  if ("20" + e.substr(1, 2) < dat.getFullYear) {
                    sec = "20"
                  } else {
                    sec = "19"
                  }
                  this.$set(this.record.fields["200"][0].e[0], "val", "Da")
                  break
              }
              this.$set(this.record.fields["200"][0].g[0], "val", e.substr(0, 1) % 2 ? "M" : "F")
              dat = new Date(sec + e.substr(1, 2), e.substr(3, 2) - 1, e.substr(5, 2), 11)
              this.$set(this.record.fields["200"][0].b[0], "val", dat.toISOString().split("T")[0] + " 00:00:00")
              this.render++
            }

            //
          },
          clnReinscrie() {
            axios.post("reader_signup/" + this.record._id).then(response => {
              if (response.data.code == 1700) {
                this.$set(this.record.fields["505"][0].b[0], "val", response.data.data.date_subscribed.split(".")[0])
                this.$set(this.record.fields["505"][0].z[0], "val", response.data.data.date_expiration.split(".")[0])
                this.render = Date.now()
              }
              EventBus.$emit("show-alert", { message: response.data.msg, timeOut: 7000 })
            })
          },
          clnViseaza() {
            axios.post("reader_visa/" + this.record._id).then(response => {
              if (response.data.code == 1701) {
                this.$set(this.record.fields["505"][0].e[0], "val", response.data.data.date_visa.split(".")[0])
                this.render = Date.now()
              }
              EventBus.$emit("show-alert", { message: response.data.msg, timeOut: 7000 })
            })
          },
          genBarcode(prefix) {
            if (prefix == "cln") {
              axios.get("generate_barcode_client").then(response => {
                if (response.data.code == 1350) {
                  this.$set(this.record.fields["500"][0].a[0], "val", response.data.data.barcode)
                  this.render = Date.now()
                }
              })
            } else if (prefix == "inv") {
              axios.get("generate_barcode_inventory").then(response => {
                if (response.data.code == 1350) {
                  this.$set(this.record.fields["200"][0].a[0], "val", response.data.data.barcode)
                  this.render = Date.now()
                }
              })
            } else if (prefix == "ninv") {
              axios.get("generate_number_inventory").then(response => {
                if (response.data.code == 1352) {
                  this.$set(this.record.fields["100"][0].a[0], "val", response.data.data.nr_inv)
                  this.render = Date.now()
                }
              })
            }
          },
          validateDuplicate(mnd, dup, v) {
            const rule = []
            if (mnd) {
              rule.push(v => !!v || this.$t("required"))
            }
            if (dup) {
              rule.push(v => this.$t("Valoarea trebuie să fie unică!!!"))
            }
            return rule
          },
          generateToken(length) {
            //edit the token allowed characters
            var a = "abcdef1234567890".split("")
            var b = []
            for (var i = 0; i < length; i++) {
              var j = (Math.random() * (a.length - 1)).toFixed(0)
              b[i] = a[j]
            }
            return b.join("")
          },
          currentDate() {
            const dat = new Date()
            return dat.toISOString().split("T")[0]
          },
          print(recordId, collection, name) {
            const record = {}
            axios.get("app_template/" + recordId).then(response => {
              this.$log("app_template", response)
              const variables = response.data.variables.variables
              const collectionsValues = response.data.variables.collections
              const newVariables = response.data.variables.fields
              for (const itm in collectionsValues) {
                if (collectionsValues[itm] !== "") {
                  record[itm] = []
                  variables.map(item => {
                    const col = item.split("_")[0]
                    const fld = item.replace(col + "_", "")
                    if (col === itm) {
                      record[itm].push({
                        variable: item,
                        field: newVariables[collectionsValues[itm]][collectionsValues[itm] + " " + fld].field,
                        default: newVariables[collectionsValues[itm]][collectionsValues[itm] + " " + fld].default
                      })
                    }
                  })
                } else {
                  if (record.Other === undefined) record.Other = []
                  variables.map(item => {
                    const col = item.split("_")[0]
                    if (col === itm) {
                      record.Other.push({
                        variable: item,
                        field: "",
                        default: ""
                      })
                    }
                  })
                }
              }
              this.$log("record ", record)
              this.$log("variables ", variables)
              this.$log("collectionsValues ", collectionsValues)
              record[collection].map(itm => {
                itm.default = this.resolvePath(itm.field, this.record)
              })
              const raport = {}
              raport.id_app_template = recordId
              raport.doc_name = name
              raport.company_id = this.companyId
              raport.table_values = {}
              for (const itm in record) {
                // this.$log("itm ", itm)
                if (itm) {
                  record[itm].map(item => {
                    raport.table_values[item.variable] = item.default != null ? item.default : ""
                  })
                }
              }
              this.$log("raport ", raport)
              axios
                .post("app_template_exec/", raport)
                .then(response => {
                  this.$log("saveData", response)
                  if (response.status === 200) {
                    window.open(axios.defaults.baseURL + "app_doc_download/?f=" + response.data.id_app_doc, "_parent")
                  }
                })
                .catch(error => {
                  this.$log("error ", error)
                  EventBus.$emit("show-alert", { message: "error", timeOut: 7000 })
                })
            })
          },
          getscopus(id, type) {
            this.$log("getscopus ", id)
            this.$log("getscopus ", type)
            axios.get("infuni/scopus/" + type + "/" + id).then(response => {
              this.$log("getscopus ", response)
              EventBus.$emit("show-alert", {
                message: "Scopus data updated.",
                timeout: 7000
              })
            })
          },
          getpublons(id, type) {
            this.$log("getpublons1 ", id)
            this.$log("getpublons2 ", type)
            // axios.get("infuni_publons/" + id).then(response => {
            //   this.$log("get rid ", response)
            // })
          },
          updatepublons(id, type) {
            this.$log("updatepublons1 ", id)
            this.$log("updatepublons2 ", type)
            axios.get("infuni_publons/" + id).then(response => {
              this.$log("get rid ", response)
              const etag = response.data._etag
              response.data._created = undefined
              response.data._etag = undefined
              response.data._latest_version = undefined
              response.data._links = undefined
              response.data._updated = undefined
              response.data._version = undefined
              //all reviews publications editorial_boards
              this.getacademic(response.data.ids.rid, type).then(resp => {
                this.$log("getaca ", resp)
                if (type == "all") {
                  response.data = resp.data
                } else if (type == "") {
                  resp.data.reviews.review_list = response.data.reviews.review_list
                  resp.data.publications.publication_list = response.data.publications.publication_list
                  resp.data.editorial_boards.editorial_board_list = response.data.editorial_boards.editorial_board_list
                  response.data = resp.data
                } else if (type == "publications") {
                  response.data.publications.publication_list = resp.data
                } else if (type == "editorial_boards") {
                  response.data.editorial_boards.editorial_board_list = resp.data
                } else if (type == "reviews") {
                  response.data.reviews.review_list = resp.data
                }
                axios
                  .put("infuni_publons/" + id, response.data, {
                    headers: {
                      "If-Match": etag
                    }
                  })
                  .then(res => {
                    EventBus.$emit("show-alert", {
                      message: response.data.publishing_name + " " + type + " updated.",
                      timeout: 7000
                    })
                    this.$log("change ", res)
                  })
              })
            })
          },
          getacademic(id, type) {
            return new Promise((resolve, reject) => {
              axios
                .get("/infuni/publons/academic/" + id + (type ? "/" + type : ""))
                .then(resp => {
                  resolve(resp)
                })
                .catch(() => {
                  reject(new Error("API Error"))
                })
            })
          },

          calcTotal() {
            //this.$log("md5 ", md5("dd"))
            let untaxed = 0
            let taxes = 0
            this.record.order_products.map(itm => {
              if (itm.total) {
                itm.taxes.map(tax => {
                  if (this.dictionary.customer_taxes.find(elm => elm.value == tax).val) {
                    taxes += (itm.total * this.dictionary.customer_taxes.find(elm => elm.value == tax).val) / 100
                  }
                })

                untaxed += itm.total
              }
            })
            taxes = Math.floor(taxes * 100) / 100
            this.$set(this.record, "untaxed", untaxed)
            this.$set(this.record, "taxes", taxes)
            this.$set(this.record, "total", untaxed + taxes)
          },
          createURL(img, fil) {
            this.$set(this.dictionary, img, URL.createObjectURL(fil.target.files[0]))
          },
          fileChanged(e) {
            this.file = e.target.files[0]
            this.urlImg = URL.createObjectURL(this.file)
            this.fileName = this.file.name
          },
          checkTVA(e) {
            if (e.length > 5) {
              const dat = new Date()
              axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
                if (response.data.found.length > 0) {
                  this.record.invoice.tva = response.data.found[0].scpTVA
                }
              })
            }
          },
          loadCUIData(e) {
            if (e.length > 5) {
              const dat = new Date()
              axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
                if (response.data.found.length > 0) {
                  this.record.invoice.tva = response.data.found[0].scpTVA
                  this.record.name = response.data.found[0].denumire
                  const adr = response.data.found[0].adresa.split(",")
                  if (adr.length > 2) {
                    this.record.address.address = response.data.found[0].adresa.replace(adr[0] + "," + adr[1] + ",", "")
                  }
                  this.record.address.locality = adr[1]
                  this.record.address.country = "România"
                  if (adr[0].substr(0, 3) == "JUD") {
                    const jud = adr[0].substr(5).toLowerCase()
                    this.record.address.county = adr[0].substr(5)
                    this.dictionary.county.map(itm => {
                      if (itm.value.toLowerCase() == jud) {
                        this.record.address.county = itm.value
                      }
                    })
                  } else {
                    this.record.address.county = "Bucureşti"
                  }
                }
              })
            }
          },
          addSubItem(e, ind, pa) {
            this.$log("addSubItem ", this.recordView)

            // this.$log("sum ", this.func(5, 7))
            const newc = JSON.parse(JSON.stringify(this.resolvePath(pa ? pa : e, this.recordView)[0]))
            if (typeof newc == "object") {
              newc.iiid = this.idGlobal++
            }
            if (ind == null) {
              this.resolvePath(e, this.record).push(newc)
            } else {
              this.resolvePath(e, this.record).splice(ind, 0, newc)
            }
            this.render = Date.now()
          },
          delSubItem(e, ind, pre) {
            this.$log("delSubIteme ", e)
            this.$log("delSubIteme pre ", pre)
            this.$log("delSubItem ind ", ind)
            this.$log("delSubItem val ", this.resolvePath(e, this.record)[ind])
            this.$log("delSubIteme id ", this.record._id)
            if (e.substr(0, 8) == "fields.4" && (pre == "mon" || pre == "cln" || pre == "evn")) {
              axios
                .get(
                  "/marc/delete_record_linking/" +
                    this.prefix +
                    "/" +
                    e.substr(7, 3) +
                    "/" +
                    this.record._id +
                    "/" +
                    this.resolvePath(e, this.record)[ind].link
                )
                .then(response => {
                  this.$log("delete_record_linking ", response)
                  //this.setLinkValue(response.data)
                })
            }
            //this.$log("delSubItem ind2 ", ind)
            this.resolvePath(e, this.record).splice(ind, 1)
            this.render++
            //this.$log("delSubItem ind2 ", this.resolvePath(e, this.record))
            //this.$log("delSubItem ind3 ", this.record)
            if (this.resolvePath(e, this.record).length == 0) {
              this.addSubItem(e)
            }
          },
          createAndEdit(e, arr, field, ind) {
            //searchRelation(arr, field, ind, res, fld) {
            this.linkArray = arr
            this.linkField = field
            this.linkIndex = ind
            this.$emit("createAndEdit", e)
          },
          searchRelationMarc(field, subfield, ind, prefix, ctg) {
            this.linkPrefix = prefix
            this.linkField = field
            this.linkSubfield = subfield
            this.linkIndex = ind
            this.linkAction = "marc"
            this.searchId = Date.now()
            let where = ""
            let vTable = ""
            if (ctg) {
              where = '"ctg":' + '"' + ctg + '"'
              const ctgview = this.$store.getters.appViewTableCollectionCategory("marc_" + prefix, ctg)
              if (ctgview.length > 0) {
                vTable = ctgview[0]._id
              }
            }
            this.$emit("openSearch", {
              res: "ebib_marc_" + prefix,
              fld: null,
              id: this.searchId,
              config: { where: where, viewTable: vTable, ctg: ctg }
            })
          },
          searchRelation(arr, field, ind, res, fld, act) {
            this.linkArray = arr
            this.linkField = field
            this.linkIndex = ind
            this.linkAction = act
            this.$emit("openSearch", { res: res, fld: fld })
          },
          setLink(field, subfield, ind, prefix, ctg, e) {
            this.$log("setlink1 ", e)
            //this.$log("setlink2 ", fields)
            if (typeof e == "object") {
              this.linkField = field
              this.linkSubfield = subfield
              this.linkIndex = ind
              this.linkAction = "marc"
              axios.get("ebib_marc_" + prefix + "/" + e._id).then(response => {
                this.$log("ressetlink ", response)
                this.setLinkValue(response.data)
              })
            } else if (!e) {
              this.linkField = field
              this.linkSubfield = subfield
              this.linkIndex = ind
              this.linkAction = "marc"
              e = { _id: undefined, fields: {} }
              // e._id = undefined
              // e.fields = {}
              // e.fields[field] = []
              // const s = {}
              // s[subfield] = [{ val: "" }]
              // e.fields[field].push(s)
              //this.$log("setlink1 note e", e)
              this.setLinkValue(e)
              this.render++
            }
          },
          setLinking(field, id) {
            axios.get("/marc/create_record_linking/mon/" + field + "/" + this.record._id + "/" + id).then(response => {
              axios.get("ebib_marc_mon/" + this.record._id).then(res => {
                this.record._etag = res.data._etag
              })
            })
          },
          setLinkValue(e) {
            this.$log("setLinkValuesetLinkValue1 ", e)
            //this.$log("this.linkArray ", this.linkArray)
            //this.$log("this.linkFields ", this.linkField)
            //this.$log("this.linkSubfield ", this.linkSubfield)
            //this.$log("this.linkIndex ", this.linkIndex)
            //this.$log("this.linkAction ", this.linkAction)
            if (this.linkAction == "marc") {
              if (e) {
                //this.$log("flds ", this.record.fields[this.linkField])
                //this.$log("pre ", this.linkPrefix)
                //this.$log("this.linkField.substr(0, 1) ", this.linkField.substr(0, 1))
                if (
                  this.linkField.substr(0, 1) == "4" &&
                  (this.prefix == "mon" || this.prefix == "cln" || this.prefix == "evn")
                ) {
                  axios
                    .get(
                      "/marc/create_record_linking/" +
                        this.prefix +
                        "/" +
                        this.linkField +
                        "/" +
                        this.record._id +
                        "/" +
                        e._id
                    )
                    .then(response => {
                      //this.$log("create_record_linking ", response)
                      axios.get("ebib_marc_" + this.prefix + "/" + this.record._id).then(res => {
                        //this.$log("res leg ", res)
                        this.record._etag = res.data._etag
                      })
                      //this.setLinkValue(response.data)
                    })
                }
                this.$set(this.record.fields[this.linkField][this.linkIndex], "link", e._id)
                //this.$log("flds ", this.record.fields[this.linkField][this.linkIndex])
                for (const itm in this.record.fields[this.linkField][this.linkIndex]) {
                  //this.$log("itm ", itm)
                  if (itm.length == 1) {
                    // this.$log(
                    //   "schemaContent ",
                    //   this.schema.fields.schema[this.linkField].schema.schema[itm].schema.schema.val.meta
                    // )
                    const tofld = this.schema.fields.schema[this.linkField].schema.schema[itm].schema.schema.val.meta
                      .to_field
                    const tosub = this.schema.fields.schema[this.linkField].schema.schema[itm].schema.schema.val.meta
                      .to_subfield
                    //this.$log(itm + " - " + tofld + " - " + tosub)
                    //this.$log("val1 ", search.value.fields)
                    if (tosub) {
                      if (e.fields[tofld]) {
                        if (e.fields[tofld][0][tosub]) {
                          //this.$log("set val ", e.fields[tofld][0][tosub][0].val)
                          this.$set(
                            this.record.fields[this.linkField][this.linkIndex][itm][0],
                            "val",
                            e.fields[tofld][0][tosub][0].val
                          )
                          this.render++
                        } else {
                          this.$set(this.record.fields[this.linkField][this.linkIndex][itm][0], "val", "")
                          this.render++
                        }
                      } else {
                        this.$set(this.record.fields[this.linkField][this.linkIndex][itm][0], "val", "")
                        this.render++
                      }
                    } else if (tofld) {
                      //this.$log("tofld ", this.resolvePath(tofld, e))
                      //sub.value = this.resolvePath(tofld, e)
                      this.$set(
                        this.record.fields[this.linkField][this.linkIndex][itm][0],
                        "val",
                        this.resolvePath(tofld, e)
                      )
                      this.render++
                    }
                  }
                }
                this.render = Date.now()
              }
            } else {
              if (this.linkAction == "order_products") {
                this.record.products_relations[this.linkIndex] = e._id
                this.$set(this.linkArray[this.linkIndex], "product", e.name)
                this.$set(this.linkArray[this.linkIndex], "description", e.description)
                this.$set(this.linkArray[this.linkIndex], "quantity", 1)
                this.$set(this.linkArray[this.linkIndex], "unit", e.mesaure)
                this.$set(this.linkArray[this.linkIndex], "price", e.price)
                this.$set(this.linkArray[this.linkIndex], "taxes", e.customer_taxes)
                //this.$set(this.linkArray[this.linkIndex], "discount", 0)
                this.$set(this.linkArray[this.linkIndex], "total", 1 * e.price)
                this.calcTotal()
                this.render = Date.now()
                return
              }
              if (this.linkAction == "optional_products") {
                this.record.optional_relations[this.linkIndex] = e._id
                this.$set(this.linkArray[this.linkIndex], "product", e.name)
                this.$set(this.linkArray[this.linkIndex], "description", e.description)
                this.$set(this.linkArray[this.linkIndex], "quantity", 1)
                this.$set(this.linkArray[this.linkIndex], "unit", e.mesaure)
                this.$set(this.linkArray[this.linkIndex], "price", e.price)
                //this.$set(this.linkArray[this.linkIndex], "discount", 0)
                this.render = Date.now()
                return
              }
              if (this.linkIndex != null) {
                if (this.linkField != null) {
                  //this.linkArray[this.linkIndex][this.linkField] = e
                  this.$set(this.linkArray[this.linkIndex], this.linkField, e)
                } else {
                  if (this.linkIndex == -1) {
                    e.iiid = this.idGlobal++
                    this.linkArray.push(e)
                  } else {
                    //this.linkArray[this.linkIndex] = e
                    if (typeof e == "object") {
                      e.iiid = this.idGlobal++
                    }
                    this.$set(this.linkArray, this.linkIndex, e)
                  }
                }
                this.render = Date.now()
              } else {
                //this.$log("settt")
                //this.linkArray[this.linkField] = {}
                //this.linkArray[this.linkField] = undefined
                this.$set(this.linkArray, this.linkField, e)
                this.render = Date.now()
                //this.$se
              }
              if (this.linkAction == "quotations_customer") {
                this.$log("select customer ", e)
                this.$set(this.record, "fiscal_position", e.invoice.fiscal_position)
                if (e.invoice.sales_person) {
                  axios.get("app_user/" + e.invoice.sales_person).then(response => {
                    this.$set(this.record, "sales_person", response.data)
                  })
                }
                if (e.relations_type) {
                  e.relations_type.map((itm, ind) => {
                    if (itm == "delivery") {
                      axios.get("contabil_client/" + e.relations[ind]).then(response => {
                        this.$set(this.record, "delivery_address", response.data)
                      })
                    }
                    if (itm == "invoice") {
                      axios.get("contabil_client/" + e.relations[ind]).then(response => {
                        this.$set(this.record, "invoice_address", response.data)
                      })
                    }
                  })
                }
                if (!this.record.delivery_address._id) {
                  this.$set(this.record, "delivery_address", e)
                }
                if (!this.record.invoice_address._id) {
                  this.$set(this.record, "invoice_address", e)
                }
              }
            }
            this.linkAction = null
          },
          resolvePath(path, obj) {
            return path.split(".").reduce(function(prev, curr) {
              return prev ? prev[curr] : null
            }, obj || self)
          }
        },
        template: this.schema.template
      }
      // })
      this.$nextTick(() => {
        this.comp = "htm-view"
      })
    },
    setLinkValue(e) {
      this.$log("setLinkValuesetLinkValue")
      this.$refs.htmView.setLinkValue(e)
    },
    createAndEdit(e) {
      this.selectId = Date.now()
      this.$log("createAndEdit ", e)
      //this.$refs.createEdit.showData(e, this.selectId)
      EventBus.$emit("create-and-edit", { dictionary: e, id: this.selectId })
    },
    openSearch(e) {
      //this.$refs.searchLink.open(e.res, e.fld)
      this.searchId = Date.now()
      EventBus.$emit("search-link", { resource: e.res, field: e.fld, id: this.searchId, config: e.config })
    }
  }
}
</script>
<style scoped></style>
