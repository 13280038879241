<template>
  <v-card v-bind="$attrs" :class="classes" class="v-card--material pl-3 pr-3 pt-3 pb-1 mt-0">
    <div style="display:-webkit-box">
      <v-avatar v-if="avatar" size="128" class="mx-auto v-card--material__avatar elevation-6" color="grey">
        <v-img :src="avatar" />
      </v-avatar>
      <v-tooltip v-else top>
        <template v-slot:activator="{ on }">
          <v-sheet
            :class="{
              'pa-3': !$slots.image
            }"
            :style="categories.length > 0 ? 'cursor:pointer' : ''"
            :color="color"
            :max-height="icon ? 90 : undefined"
            :width="inline || icon ? 'auto' : '100%'"
            elevation="6"
            class="text-start mt-0  mb-0"
            dark
            @click="iconClick"
            v-on="on"
          >
            <slot v-if="$slots.heading" name="heading" />
            <slot v-else-if="$slots.image" name="image" />
            <div v-else-if="title && !icon" class="display-1 font-weight-light" v-text="title" />
            <v-icon v-else-if="icon" size="24" v-text="icon" />
            <div v-if="text" class="headline font-weight-thin" v-text="text" />
          </v-sheet>
        </template>
        <span v-if="categories.length > 0">{{ $t("Afișează din toate categoriile") }}</span>
        <span v-else>{{ title }}</span>
      </v-tooltip>
      <div v-if="$slots['after-heading']" class="ml-6">
        <slot name="after-heading" />
      </div>
      <v-col v-if="hoverReveal" cols="12" class="text-center py-0 mt-2">
        <slot name="reveal-actions" />
      </v-col>
      <!-- <div v-else-if="categories.length > 0" class="d-flex ml-4"> -->
      <!-- <span class="card-title font-weight-light">{{ title + " - " }}</span> -->

      <!-- <v-menu bottom right offset-y open-on-hover>
          <template v-slot:activator="{ on }">
            <span class="card-title font-weight-light" v-on="on">
              {{ category ? category : title }}
              <v-icon>mdi-menu-down</v-icon>
            </span>
          </template>
          <v-list dense>
            <v-list-item v-for="cat in categories" :key="cat.id" @click="$emit('change-category', cat)">
              {{ cat.lblRo }}
            </v-list-item>
          </v-list>
        </v-menu> -->

      <v-tabs
        v-else-if="categories.length > 1"
        v-model="selectedTab"
        optional
        class="ml-4"
        show-arrows
        @change="changeCategory"
      >
        <v-tab v-for="cat in categories" :key="cat.id">
          {{ cat.lblRo }}{{ cat.count ? " (" + cat.count + ")" : "" }}
        </v-tab>
      </v-tabs>
      <!-- </div> -->
      <div v-else-if="icon && title" class="mt-3 ml-4">
        <div class="card-title font-weight-light" v-text="title" />
      </div>
    </div>

    <slot />

    <template v-if="$slots.actions">
      <v-divider class="mt-2" />

      <v-card-actions class="pb-0">
        <slot name="actions" />
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
export default {
  name: "MaterialCard",

  props: {
    avatar: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "primary"
    },
    hoverReveal: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: undefined
    },
    image: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    category: {
      type: String,
      default: ""
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    selectedTab: 0
  }),

  computed: {
    classes() {
      return {
        "v-card--material--has-heading": this.hasHeading,
        "v-card--material--hover-reveal": this.hoverReveal
      }
    },
    hasHeading() {
      return Boolean(this.$slots.heading || this.title || this.icon)
    },
    hasAltHeading() {
      return Boolean(this.$slots.heading || (this.title && this.icon))
    }
  },
  methods: {
    iconClick() {
      if (this.categories.length > 1) {
        this.$emit("icon-click")
        this.selectedTab = undefined
      }
    },
    changeCategory(e) {
      if (e != undefined) {
        this.$emit("change-category", this.categories[e])
      } else {
        this.$emit("icon-click")
      }
    }
  }
}
</script>

<style lang="sass">
.v-card--material
  &__avatar
    position: relative
    top: -64px
    margin-bottom: -32px

  &__heading
    position: relative
    top: -40px
    transition: .3s ease
    z-index: 1

  &.v-card--material--hover-reveal:hover
    .v-card--material__heading
      transform: translateY(-40px)
</style>
