<template>
  <div>
    <span class="d-none">{{ render }}</span>
    <componet
      :is="type"
      ref="autocomplete"
      :item-text="itemText"
      :item-value="itemValue"
      :error-messages="errorMessages"
      :rules="inputValidate"
      :items="items"
      :value="value"
      return-object
      append-icon=""
      :clearable="clearable"
      :dense="dense"
      :label="label"
      :hide-details="hideDetails"
      :no-data-text="$t(noDataText)"
      :search-input.sync="search"
      :autofocus="autofocus"
      :allow-overflow="true"
      :placeholder="placeholder"
      @keydown.ctrl.f.stop.prevent="$emit('search')"
      @keydown.tab="tab = true"
      @focus="
        searchRecordsFocus(value)
        $emit('focus')
      "
      @paste="
        pasteValue = true
        loadedAllData = false
      "
      @change="changeValue"
      @blur="
        blurVal
        $emit('blur')
      "
    >
      <!-- <template v-if="showAdd" v-slot:prepend-item>
        <v-btn color="primary" class="ml-6" small @click="addData">
          {{ $t("Crează înregistrare de autoritate") }}
        </v-btn>
      </template> -->
      <template v-if="!(type == 'v-combobox' && viewType != 'data')" v-slot:append-item>
        <div
          v-if="!isLastPage"
          v-observe-visibility="{
            callback: visibilityChanged
          }"
          class="pl-4 body1 text-center"
        >
          {{ "1-" + items.length }}
          {{ " " + $t("din") + " " }}{{ totalRecords }}
        </div>
      </template>
      <template v-slot:append>
        <slot name="append" />
      </template>
      <template v-slot:append-outer>
        <slot name="append-outer" />
        <v-btn v-if="errorMessages" color="info" small @click="addData">{{ $t("Creează") }}</v-btn>
      </template>
      <template v-slot:prepend>
        <slot name="prepend" />
      </template>
    </componet>
    <component :is="currentEditForm" ref="addDataPopUp" @mounted="addDataMounted" @reload="recordAdded" />
  </div>
</template>

<script>
import axios from "../../plugins/axios"
import { debounce } from "../../util/helpers"
export default {
  props: {
    label: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    value: { type: String, default: "" },
    field: { type: String, default: "" },
    category: { type: String, default: null },
    itemValue: { type: String, default: "" },
    itemText: { type: String, default: "" },
    collection: { type: String, default: "" },
    where: { type: String, default: "" },
    maxResults: { type: Number, default: 10 },
    searchminLength: { type: Number, default: 2 },
    type: { type: String, default: "v-combobox" },
    viewType: { type: String, default: "data" },
    returnObject: { type: Boolean, default: false },
    clearable: { type: Boolean, default: true },
    autofocus: { type: Boolean, default: false },
    showAdd: { type: Boolean, default: false },
    autoAdd: { type: Boolean, default: false },
    dense: { type: Boolean, default: true },
    hideDetails: { type: [Boolean, String], default: true },
    recordField: { type: String, default: "" },
    rules: { type: Array, default: () => [] }
  },
  data() {
    return {
      pasteValue: false,
      isLastPage: false,
      loadedAllData: false,
      tab: false,
      render: 0,
      items: [],
      totalResults: 1000,
      isLoading: false,
      showMenu: false,
      search: "",
      searchOld: "",
      oldSearch: null,
      oldValue: null,
      changedValue: null,
      noDataText: "Introduceți cel puțin 2 caractere pentru căutare...",
      totalRecords: 0,
      page: 1,
      componentToDisplay: null
    }
  },
  computed: {
    errorMessages() {
      if (this.viewType == "marcath") {
        if (this.items.length == 0 && this.search) {
          return "Valoarea nu există!"
        } else if (this.items.length > 0 && this.search) {
          if (!this.items.find(itm => itm[this.itemValue] == this.search)) {
            return "Valoarea nu există!"
          }
        }
      }
      return ""
    },
    inputValidate() {
      const rules = [...this.rules]
      if (this.viewType == "marcath") {
        if (this.items.length == 0 && this.search) {
          rules.push("Valoarea nu există!")
        } else if (this.items.length > 0 && this.search) {
          if (!this.items.find(itm => itm[this.itemValue] == this.search)) {
            rules.push("Valoarea nu există!")
          }
        }
      }
      return rules
    },
    currentEditForm: function() {
      if (this.componentToDisplay) {
        this.$log("currentEditForm")
        return () => import("../../views/settings/AddDataPopUp.vue")
      }
      return null
    }
  },
  watch: {
    search(val) {
      //this.$log("s label ", this.label)
      //this.$log("search auto " + val)
      //this.$log("search auto2 " + this.tab)
      if (val != null) {
        this.searchChanged(true)
        //this.$log("searchRecords searchChanged")
      }
      if (val == "") {
        this.changeValue(val)
      }
      // if(this.tab){
      //   this.search = this.searchOld
      // }
      // this.searchOld = this.search
      // this.tab = false
    },
    itemText() {
      //this.$log("change itemtext ")
      this.items = []
      this.oldSearch = this.value + "aa"
    },
    value(newv, oldv) {
      //this.$log("s label ", this.label)
      //this.$log("value changed ac ", this.value)
      //this.$log("value changed ac old ", oldv)
      //this.$log("value changed ac new ", newv)
      //this.$log("value changed ac type ", this.items)
      if (oldv != newv && this.type == "v-autocomplete") {
        this.pasteValue = true
        //this.$log("value changed ac items ", this.items)

        // this.$log(
        //   "value changed ac index ",
        //   this.items.findIndex(itm => itm[this.itemValue] == this.value)
        // )
        // this.$log("value changed ac this.itemValue ", this.itemValue)

        if (this.items.findIndex(itm => itm[this.itemValue] == this.value) < 0) {
          this.loadedAllData = false
        }

        //this.loadedAllData = false
        this.searchRecords(this.value, true)
        //this.$log("searchRecords value change")
      }
    }
  },
  created() {
    //this.$log("created ac " + this.value + " - " + this.label)
    //this.$log("created ac " + this.type)
    this.oldSearch = this.value + "aa"
    if ((this.viewType == "marcath" || this.type == "v-autocomplete") && this.value) {
      this.searchRecords(this.value, true)
      //this.$log("searchRecords created")
    }
    //const dic = {}
    //dic[this.itemText] = this.value
    //dic[this.itemValue] = this.value
    //this.items.push(dic)
  },
  mounted() {
    //this.$log("mounted ac " + this.value + " - " + this.oldSearch + " - " + this.items.length)
    //this.$log("mount ")
    //this.searchRecords("")
  },
  methods: {
    addData() {
      if (this.autoAdd) {
        //this.launchEditForm()
      } else {
        this.launchEditForm()
      }
    },
    launchEditForm() {
      this.$log("launchEditForm")
      this.componentToDisplay = "views/settings/AddData.vue"
      if (this.$refs.addDataPopUp) {
        this.addDataMounted()
      }
    },
    addDataMounted() {
      const col = this.collection.substring(5)
      let view
      const tables = this.$store.getters.appSchema.filter(itm => itm.collection == col)
      const schema = this.$store.getters.appViewCollection(tables[0].collection)
      if (schema.findIndex(itm => itm.ctg == this.category) > -1) {
        if (schema.findIndex(itm => itm.ctg == this.category && itm.default) > -1) {
          view = schema.find(itm => itm.ctg == this.category && itm.default)._id
        } else {
          view = schema.find(itm => itm.ctg == this.category)._id
        }
      } else {
        view = schema.find(itm => itm.default)._id
      }
      this.$nextTick(() => {
        const rdef = {}
        if (this.recordField) {
          rdef.fields = {}
          let vala = this.search
          let valb = ""
          if (this.category == "nume-personal") {
            if (vala.split(",")[1]) {
              valb = vala.split(",")[1]
              valb = valb.split("(")[0]
              valb = valb.trim()
            }
            vala = vala.split(",")[0]
          }
          rdef.fields[this.recordField] = [
            {
              a: [{ val: vala }]
            }
          ]
          //this.$log("valb ", valb)
          if (valb) {
            rdef.fields[this.recordField][0].b = [{ val: valb }]
          }
        }
        //this.$log("rdef ", rdef)
        this.$refs.addDataPopUp.addData(tables[0], view, rdef, this.category)
      })
    },
    recordAdded(e) {
      //this.$log("recordAdded", e)
      this.pasteValue = true
      this.searchRecords(this.search, true)
      this.$emit("input", e)
    },
    clickApp() {
      this.$log("clickApp")
    },
    blurVal() {
      //this.$log("s label ", this.label)
      //this.$log("blurVal1 ", this.value)
      this.$log("blurVal2 ", this.search)
      this.showMenu = false
      //if (!this.search && !this.value) {
      this.changeValue(this.search)
      //}
    },
    changeValue(e) {
      //this.$log("s label ", this.label)
      this.$log("auto chg1 ", e)
      //this.$log("auto chg2 ", this.value)
      //this.$log("auto chg2 ", this.itemValue)
      //this.$log("auto chg3 ", e[this.itemValue])
      this.changedValue = e

      if (!e) {
        this.emitValue("")
        this.searchRecords("", true)
        //this.$log("searchRecords changevalue")
        this.showMenu = true
      } else {
        if (typeof e == "object") {
          if (this.returnObject) {
            this.emitValue(e)
          } else {
            if (this.viewType == "data") {
              this.emitValue(this.resolvePath(this.itemValue, e).toString())
            } else {
              this.emitValue(e[this.itemValue].toString())
            }
          }
        } else {
          this.emitValue(e.toString())
        }
      }
    },
    emitValue(val) {
      this.$log("emitValue ", val)
      if (val != this.value) {
        this.$emit("input", val)
        this.$emit("changedValue", this.changedValue)
      }
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    },
    searchRecordsFocus(val) {
      this.showMenu = true
      this.searchRecords(val, true)
      //this.$log("searchRecords focus")
    },
    searchChanged: debounce(function(clear) {
      //this.$log("s label ", this.label)
      //this.$log("debounce ", clear)
      this.searchRecords(this.search, clear)
    }, 1000),
    visibilityChanged(e) {
      //this.$log("visibilityChanged1 ", e)
      //this.$log("visibilityChanged2 ", this.items.length)
      //this.$log("visibilityChanged3 ", this.totalResults)
      if (e && this.items.length < this.totalResults) {
        this.page++
        this.searchRecords(this.search, false)
        //this.searchChanged(false)
        //this.$log("searchRecords lazy")
      }
    },
    searchRecords(val, clear) {
      //this.$log("s label ", this.label)
      this.$log("searchRecords ac1 ", val)
      this.$log("searchRecords ac2 ", this.value)
      this.$log("searchRecords ac3 ", this.search)
      this.$log("searchRecords ac items ", this.items)
      this.$log("searchRecords ac itemvalue ", this.itemValue)
      this.$log("searchRecords ac past ", this.pasteValue)
      this.$log("searchRecords ac loaded ", this.loadedAllData)
      if (val == null) val = ""
      if (val.length < this.searchminLength) {
        this.noDataText = "Introduceți cel puțin " + this.searchminLength + " caractere pentru căutare..."
        return
      }
      // this.$log(
      //   "searchRecords " +
      //     this.isLoading +
      //     " clear:" +
      //     clear +
      //     " items.length:" +
      //     this.items.length +
      //     " total:" +
      //     this.totalResults +
      //     " maxResults:" +
      //     this.maxResults +
      //     " page:" +
      //     this.page +
      //     "  val.length:" +
      //     val.length +
      //     " oldSearch.length:" +
      //     this.oldSearch.length
      // )
      //if (this.isLoading) return
      if (clear && !this.loadedAllData) {
        this.page = 1
        //this.items = []
      }
      //this.items.length >= this.maxResults
      if (this.items.findIndex(itm => itm[this.itemValue] == val) >= 0) {
        this.$log("finddd ", this.items)
        this.render++
        return
      }
      this.pasteValue = true

      if (
        !this.loadedAllData &&
        (this.pasteValue || this.items.length < this.totalResults || val.length < this.oldSearch.length)
      ) {
        this.$log("searchRecords ac clear ", clear)
        if (clear) {
          this.items = []
        }
        this.isLoading = true
        this.pasteValue = false
        this.oldSearch = val
        let where = ""
        if (val.length > 0) {
          const val2 = val.replace(/[-[/{}()*+?.^$|\]\\]/g, "\\\\$&")
          where =
            "&where={" +
            this.where +
            '"' +
            this.itemText +
            '":{"$regex":"' +
            encodeURIComponent(val2) +
            '","$options":"i"}}'
        } else {
          where = "&where={" + this.where + '"' + this.itemText + '":{"$exists":true}}'
        }
        let col = "{"
        if (this.itemValue !== "") {
          col += '"' + this.itemValue + '":1'
        }
        if (this.itemText !== "" && this.itemValue != this.itemText) {
          col += col != "" ? "," : ""
          col += '"' + this.itemText + '":1'
        }
        col += "}"
        if (
          (this.collection == "ebib_marc_mon" || this.collection == "ebib_marc_ath") &&
          this.itemValue == "fields.200.a.val"
        ) {
          col = '{"fields.200":1,"ctg":1}'
        }
        // if (clear) {
        //   this.page = 1
        // }
        // this.$log(
        //   "loading data ",
        //   this.collection + "?projection=" + col + where + "&max_results=" + this.maxResults + "&page=" + this.page
        // )
        //custom_query?collection=contabil_client&mode=distinct&fld=address.locality&lim=3&off=3
        //custom_query?collection=ebib_marc_cln&mode=distinct&fld=fields.210.g.val&lim=10&off=0
        //marc/autocomplete/inv/500a/b/Sec/10/0/include_count
        //dashboard/view_libraries_counters

        // axios
        //   .get("custom_query?collection=ebib_marc_cln&mode=distinct&fld=fields.210.g.val&lim=10&off=0")
        //   .then(response => {
        //     this.$log("Baiaq ", response)
        //   })
        this.noDataText = "Căutare date..."
        if (this.viewType == "marc" && this.type == "v-combobox" && this.itemText == this.itemValue) {
          if (val) {
            const arr = this.itemValue.split(".")

            axios
              .get(
                "marc/autocomplete/" +
                  this.collection.split("_")[2] +
                  "/" +
                  arr[1] +
                  "" +
                  arr[2] +
                  "/b/" +
                  encodeURIComponent(val) +
                  "/10/0/include_count"
              )
              .then(response => {
                //this.$log("Baia ", response)
                response.data.data.map(val => {
                  const r = {}
                  r[this.itemValue] = val
                  this.items.push(r)
                })
                this.render++
              })
              .finally(() => {
                this.isLoading = false
                if (this.items.length < 1) {
                  this.noDataText = "Nu sunt date"
                }
              })
          }
        } else {
          let sort = this.itemText
          if (!sort) {
            sort = this.itemValue
          }
          sort = "_id"
          axios
            .get(
              this.collection +
                "?projection=" +
                col +
                where +
                '&sort=[("' +
                sort +
                '",1)]&max_results=' +
                this.maxResults +
                "&page=" +
                this.page
            )
            .then(response => {
              //this.$log("this.viewType  ", this.viewType)
              this.totalRecords = response.data._meta.total
              if (this.viewType == "data") {
                this.items = response.data._items
              } else {
                response.data._items.map(itm => {
                  const rec = {}
                  rec._id = itm._id
                  for (const itms in itm) {
                    if (itms != "fields") {
                      rec[itms] = itm[itms]
                    }
                  }
                  //rec[this.itemText] = this.resolvePath(this.itemText, itm)
                  //rec[this.itemValue] = this.resolvePath(this.itemValue, itm)
                  for (const flds in itm.fields) {
                    for (const fld in itm.fields[flds]) {
                      for (const subs in itm.fields[flds][fld]) {
                        if (subs.length == 1) {
                          for (const sub in itm.fields[flds][fld][subs]) {
                            rec["fields." + flds + "." + subs + ".val"] = itm.fields[flds][fld][subs][sub].val
                          }
                        } else {
                          rec["fields." + flds + "." + subs] = itm.fields[flds][fld][subs]
                        }
                      }
                    }
                  }
                  this.items.push(rec)
                })
                this.$log("items ", this.items)
                this.$log("value ", this.value)
                this.render++
                //this.$refs.autocomplete.onScroll()
              }
              this.totalResults = response.data._meta.total
              if (val == "" && this.totalResults == this.items.length) {
                this.loadedAllData = true
              }
              //this.$log("vauto val ", this.value)
            })
            .finally(() => {
              this.isLoading = false
              if (this.items.length < 1) {
                this.noDataText = "Nu sunt date"
              }
            })
        }
      }
    }
  }
}
</script>
<style scopped></style>
<!--

-->
