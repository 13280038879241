<template>
  <v-row
    v-if="children.typeObject == 'TextInput'"
    v-show="children.visible == undefined || children.visible"
    dense
    class="text-no-wrap"
    align="center"
  >
    <v-col v-if="children.marc" class="text-right grey--text text-truncate" cols="4">
      {{ $t(children.name) }}
    </v-col>
    <v-col>
      <v-text-field
        dense
        :label="$t(children.name)"
        :value="getValue()"
        :rules="inputValidate"
        :autofocus="children.autofocus"
        @input="setValue(children.key, record, $event)"
        @mouseenter="show = true"
        @mouseleave="show = false"
      >
        <template v-slot:append>
          <v-icon
            v-show="show && index >= 0"
            @mouseenter="show = true"
            @mouseleave="show = false"
            @click="$emit('add', index)"
          >
            mdi-plus
          </v-icon>
          <v-icon
            v-show="show && index >= 0"
            @mouseenter="show = true"
            @mouseleave="show = false"
            @click="$emit('delete', index)"
          >
            mdi-minus
          </v-icon>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
  <v-row
    v-else-if="children.typeObject == 'Password'"
    v-show="children.visible == undefined || children.visible"
    dense
    class="text-no-wrap"
    align="center"
  >
    <v-col v-if="children.marc" class="text-right grey--text text-truncate" cols="4">
      {{ $t(children.name) }}
    </v-col>
    <v-col>
      <!-- <span class="d-none">{{ render }}</span> -->
      <v-text-field
        v-model="pass"
        dense
        :label="$t(children.name)"
        :rules="passValidate"
        :autofocus="children.autofocus"
        type="password"
        @input="setValue(children.key, record, $event)"
        @mouseenter="show = true"
        @mouseleave="show = false"
      >
        <template v-slot:append>
          <v-icon
            v-show="show && index >= 0"
            @mouseenter="show = true"
            @mouseleave="show = false"
            @click="$emit('add', index)"
          >
            mdi-plus
          </v-icon>
          <v-icon
            v-show="show && index >= 0"
            @mouseenter="show = true"
            @mouseleave="show = false"
            @click="$emit('delete', index)"
          >
            mdi-minus
          </v-icon>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
  <v-row
    v-else-if="children.typeObject == 'Numeric'"
    v-show="children.visible == undefined || children.visible"
    dense
    class="text-no-wrap"
    align="center"
  >
    <v-col v-if="children.marc" class="text-right grey--text text-truncate" cols="4">
      {{ $t(children.name) }}
    </v-col>
    <v-col>
      <v-text-field
        dense
        :label="$t(children.name)"
        :value="getValue()"
        :rules="inputValidate"
        :autofocus="children.autofocus"
        @input="setValue(children.key, record, $event)"
        @mouseenter="show = true"
        @mouseleave="show = false"
      >
        <template v-slot:append>
          <v-icon
            v-show="show && index >= 0"
            @mouseenter="show = true"
            @mouseleave="show = false"
            @click="$emit('add', index)"
          >
            mdi-plus
          </v-icon>
          <v-icon
            v-show="show && index >= 0"
            @mouseenter="show = true"
            @mouseleave="show = false"
            @click="$emit('delete', index)"
          >
            mdi-minus
          </v-icon>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
  <v-row
    v-else-if="children.typeObject == 'Link'"
    v-show="children.visible == undefined || children.visible"
    dense
    class="text-no-wrap"
    align="center"
  >
    <v-col v-if="children.marc" class="text-right grey--text text-truncate" cols="4">
      {{ $t(children.name) }}
    </v-col>
    <v-col>
      <div>
        <span class="d-none">{{ render }}</span>
        <v-text-field
          dense
          :label="$t(children.name)"
          :value="getValue()"
          :rules="inputValidate"
          :autofocus="children.autofocus"
          @input="setValue(children.key, record, $event)"
          @mouseenter="show = true"
          @mouseleave="show = false"
          @keydown.ctrl.f.stop.prevent="searchLink"
        >
          <template v-slot:append>
            <v-icon v-show="show" @mouseenter="show = true" @mouseleave="show = false" @click="searchLink">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
        <!-- <search-link ref="searchLink" @select="setValue(children.key, record, $event)" /> -->
      </div>
    </v-col>
  </v-row>
  <v-row
    v-else-if="children.typeObject == 'TextArea'"
    v-show="children.visible == undefined || children.visible"
    dense
    class="text-no-wrap"
    align="center"
  >
    <v-col v-if="children.marc" class="text-right grey--text text-truncate" cols="4">
      {{ $t(children.name) }}
    </v-col>
    <v-col>
      <v-textarea
        dense
        :label="$t(children.name)"
        rows="2"
        :value="getValue()"
        :rules="inputValidate"
        :autofocus="children.autofocus"
        @input="setValue(children.key, record, $event)"
      />
    </v-col>
  </v-row>
  <v-row
    v-else-if="children.typeObject == 'Image'"
    v-show="children.visible == undefined || children.visible"
    dense
    class="text-no-wrap"
    align="center"
  >
    <v-col v-if="children.marc" class="text-right grey--text text-truncate" cols="4">
      {{ $t(children.name) }}
    </v-col>
    <v-col>
      <v-img contain :src="'data:image/jpeg;base64,' + getValue()" />
    </v-col>
  </v-row>
  <v-row v-else-if="children.typeObject == 'ComboBox'" dense class="text-no-wrap" align="center">
    <v-col v-if="children.marc" class="text-right grey--text text-truncate" cols="4">
      {{ $t(children.name) }}
    </v-col>
    <v-col>
      <v-combobox
        dense
        :label="$t(children.name)"
        :value="getValue()"
        :rules="inputValidate"
        item-text="name"
        item-value="value"
        :multiple="children.type == 'list'"
        :items="dictionaryValues"
        :autofocus="children.autofocus"
        @input="setValue(children.key, record, $event)"
      />
    </v-col>
  </v-row>

  <v-row
    v-else-if="children.typeObject == 'Autocomplete'"
    v-show="children.visible == undefined || children.visible"
    dense
    class="text-no-wrap"
    align="center"
  >
    <v-col v-if="children.marc" class="text-right grey--text text-truncate" cols="4">
      {{ $t(children.name) }}
    </v-col>
    <v-col>
      <v-autocomplete
        dense
        :label="$t(children.name)"
        :value="getValue()"
        :rules="inputValidate"
        item-text="name"
        item-value="value"
        :multiple="children.type == 'list'"
        :items="dictionaryValues"
        :autofocus="children.autofocus"
        :return-object="false"
        @mouseenter="show = true"
        @mouseleave="show = false"
        @input="setValue(children.key, record, $event)"
      >
        <template v-slot:append-outer>
          <v-icon
            v-show="show && index >= 0"
            @mouseenter="show = true"
            @mouseleave="show = false"
            @click="$emit('add', index)"
          >
            mdi-plus
          </v-icon>
          <v-icon
            v-show="show && index >= 0"
            @mouseenter="show = true"
            @mouseleave="show = false"
            @click="$emit('delete', index)"
          >
            mdi-minus
          </v-icon>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
  <v-row
    v-else-if="children.typeObject == 'CheckBox'"
    v-show="children.visible == undefined || children.visible"
    dense
    class="text-no-wrap"
    align="center"
  >
    <v-col v-if="children.marc" class="text-right grey--text text-truncate" cols="4">
      {{ $t(children.name) }}
    </v-col>
    <v-col>
      <v-checkbox
        class="mt-0"
        dense
        hide-details
        :label="$t(children.name)"
        :input-value="getValue()"
        :autofocus="children.autofocus"
        @change="setValue(children.key, record, $event)"
      />
    </v-col>
  </v-row>
  <v-row
    v-else-if="children.typeObject == 'Switch'"
    v-show="children.visible == undefined || children.visible"
    dense
    class="text-no-wrap"
    align="center"
  >
    <v-col v-if="children.marc" class="text-right grey--text text-truncate" cols="4">
      {{ $t(children.name) }}
    </v-col>
    <v-col>
      <v-switch
        :label="$t(children.name)"
        :value="getValue()"
        :autofocus="children.autofocus"
        @change="setValue(children.key, record, $event)"
      />
    </v-col>
  </v-row>
  <v-row
    v-else-if="children.typeObject == 'Dateee'"
    v-show="children.visible == undefined || children.visible"
    dense
    class="text-no-wrap"
    align="center"
  >
    <v-col v-if="children.marc" class="text-right grey--text text-truncate" cols="4">
      {{ $t(children.name) }}
    </v-col>
    <v-col>
      <date-field
        :label="$t(children.name)"
        :value="getValue()"
        :autofocus="children.autofocus"
        @input="setValue(children.key, record, $event)"
      />
    </v-col>
  </v-row>
  <v-row
    v-else-if="children.typeObject == 'Date'"
    v-show="children.visible == undefined || children.visible"
    dense
    class="text-no-wrap"
    align="center"
  >
    <v-col v-if="children.marc" class="text-right grey--text text-truncate" cols="4">
      {{ $t(children.name) }}
    </v-col>
    <v-col>
      <s-date-time
        dense
        hide-details
        type="date"
        :value="getValue()"
        :autofocus="children.autofocus"
        @input="setValue(children.key, record, $event)"
      />
    </v-col>
  </v-row>
  <v-row
    v-else-if="children.typeObject == 'DateTime'"
    v-show="children.visible == undefined || children.visible"
    dense
    class="text-no-wrap"
    align="center"
  >
    <v-col v-if="children.marc" class="text-right grey--text text-truncate" cols="4">
      {{ $t(children.name) }}
    </v-col>
    <v-col>
      <s-date-time
        dense
        hide-details
        type="datetime"
        :value="getValue()"
        :autofocus="children.autofocus"
        @input="setValue(children.key, record, $event)"
      />
    </v-col>
  </v-row>
  <v-row
    v-else-if="children.typeObject == 'Time'"
    v-show="children.visible == undefined || children.visible"
    dense
    class="text-no-wrap"
    align="center"
  >
    <v-col v-if="children.marc" class="text-right grey--text text-truncate" cols="4">
      {{ $t(children.name) }}
    </v-col>
    <v-col>
      <s-date-time
        dense
        hide-details
        type="time"
        :value="getValue()"
        :autofocus="children.autofocus"
        @input="setValue(children.key, record, $event)"
      />
    </v-col>
  </v-row>
  <v-row
    v-else-if="children.typeObject == 'DateField'"
    v-show="children.visible == undefined || children.visible"
    dense
    class="text-no-wrap"
    align="center"
  >
    <v-col v-if="children.marc" class="text-right grey--text text-truncate" cols="4">
      {{ $t(children.name) }}
    </v-col>
    <v-col>
      <s-date-time
        dense
        hide-details
        type="datefield"
        :value="getValue()"
        :autofocus="children.autofocus"
        @input="setValue(children.key, record, $event)"
      />
    </v-col>
  </v-row>

  <s-marc-view
    v-else-if="children.typeObject == 'Marc'"
    ref="marc"
    :children="children"
    :schema="schema"
    :record="record"
    :fields="children.fields"
  />
  <div v-else-if="children.typeObject == 'Table'">
    <table-form :children="children" :record="record" @addItem="addItemTable" @editItem="editItemTable" />
    <v-dialog v-model="showAddTable">
      <v-card outlined>
        <v-card-title>
          {{ $t("Adaugă") }}{{ " " + (typeof children.name == "string" ? $t(children.name) : "") }}
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col v-for="el in children.children" :key="el.id" cols="12">
              <scream-view :children="el" :record="editRecord" :schema="schema" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="showAddTable = false">
            {{ $t("Anulează") }}
          </v-btn>
          <v-btn color="primary" @click="saveDataTable">
            {{ $t("Salvează") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <v-tabs v-else-if="children.typeObject == 'Tabs'">
    <v-tabs-slider />
    <v-tab v-for="el in children.children" :key="el.iiid">
      {{ el.name }}
    </v-tab>
    <v-tab-item v-for="el in children.children" :key="el.iiid">
      <v-row class="mt-4" dense>
        <v-col v-for="sub in el.children" :key="sub.iiid" cols="12">
          <scream-view :children="sub" :record="record" :schema="schema" />
        </v-col>
      </v-row>

      <!-- <scream-view :children="el" :record="record" :schema="schema"/> -->
    </v-tab-item>
  </v-tabs>
  <v-row v-else-if="children.typeObject == 'Horizontal Box'" dense>
    <v-col v-if="children.name" class="dense" cols="12">
      <v-subheader>
        {{ $t(children.name) }}
      </v-subheader>
    </v-col>
    <v-col
      v-for="(el, ind) in children.children"
      :key="el.iiid"
      class="dense"
      :class="ind > 0 ? 'pl-6' : ''"
      :md="12 / children.children.length"
      cols="12"
    >
      <scream-view :children="el" :record="record" :schema="schema" />
    </v-col>
  </v-row>
  <v-row v-else-if="children.typeObject == 'Block' && children.key != ''" dense>
    <v-col
      v-for="(rec, ind) in resolvePath(children.key, record)"
      :key="rec.iiid ? rec.iiid : ind"
      class="dense"
      cols="12"
    >
      <v-row dense>
        <span class="d-none">{{ render }}</span>
        <v-col v-if="retType(resolvePath(children.key, record)[0])" class="dense" cols="12">
          <v-subheader>
            {{ $t(children.name) }}
            <v-btn icon @click="addRow(resolvePath(children.key, record), children)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn icon @click="delRow(resolvePath(children.key, record), ind)">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </v-subheader>
        </v-col>
        <v-col v-for="el in children.children" :key="el.iiid" class="dense" cols="12">
          <scream-view v-if="retType(rec)" :children="el" :record="rec" :schema="schema" />
          <scream-view
            v-else
            :children="el"
            :values="resolvePath(children.key, record)"
            :index="ind"
            :schema="schema"
            @add="addRow(resolvePath(children.key, record), children)"
            @delete="delRow(resolvePath(children.key, record), ind)"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row v-else-if="children.typeObject == 'Block' && children.key == ''" dense>
    <v-col v-if="children.name" class="dense" cols="12">
      <v-subheader>
        {{ $t(children.name) }}
      </v-subheader>
    </v-col>
    <v-col v-for="el in children.children" :key="el.iiid" class="dense" cols="12">
      <scream-view :children="el" :record="record" :schema="schema" />
    </v-col>
  </v-row>
</template>
<script>
import axios from "../../plugins/axios"
import { EventBus } from "../../EventBus"
// import SearchLink from "./SearchLink"
import md5 from "js-md5"
import TableForm from "../../components/containers/TableForm"
import SMarcView from "../../components/containers/ScreamMarcView"
import DateField from "../../components/inputs/DateField"
import SDateTime from "../../components/inputs/DateTime"
export default {
  name: "ScreamView",
  components: {
    // SearchLink,
    SMarcView,
    TableForm,
    DateField,
    SDateTime
  },
  props: {
    schema: {
      required: true,
      type: Object
    },
    children: {
      required: true,
      type: Object
    },
    record: {
      type: Object,
      default: () => {}
    },
    values: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      default: -1
    }
  },
  data: () => ({
    searchId: "",
    dictionaryValues: [],
    render: "1",
    listAddTable: [],
    showAddTable: false,
    editRecord: {},
    editIndex: 0,
    password: "",
    show: false
  }),
  computed: {
    pass: {
      get() {
        if (this.resolvePath(this.children.key, this.record) == md5(this.password)) {
          return this.password
        }
        return ""
      },
      set(e) {
        this.password = e
      }
    },
    passValidate() {
      const rule = []
      if (this.children.required) {
        rule.push(v => !!this.resolvePath(this.children.key, this.record) || this.$t("required"))
      }
      return rule
    },
    inputValidate() {
      const rule = []
      if (this.children.required) rule.push(v => !!v || this.$t("required"))
      if (this.children.minlength) {
        rule.push(v => v.length >= this.children.minlength || this.$t("minlength", { len: this.children.minlength }))
      }
      if (this.children.maxlength) {
        rule.push(v => v.length <= this.children.maxlength || this.$t("maxlength", { len: this.children.maxlength }))
      }
      return rule
    }
  },
  mounted() {
    //this.$log("mounted ", this)
    if (this.children.dictionary) {
      const dictionary = this.children.dictionary.split(".")
      if (dictionary[0] == "all") {
        axios.get('app_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
          this.dictionaryValues = response.data._items[0].content
        })
      } else if (dictionary[0] == "system") {
        axios.get('app_system_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
          this.dictionaryValues = response.data._items[0].content
        })
      } else {
        axios.get('app_dictionary?where={"value":"' + dictionary[0] + '"}').then(response => {
          this.dictionaryValues = response.data._items[0].content
        })
      }
    }
  },
  created() {
    EventBus.$on("search-link-selected", search => {
      if (this.searchId == search.id) {
        this.setValue(this.children.key, this.record, search.value)
      }
    })
  },
  methods: {
    generateRecord() {
      this.$log("grec")
      if (this.$refs.marc) {
        this.$refs.marc.generateRecord()
      }
    },
    editItemTable(itm) {
      this.$log("editItemTable ", itm)
      this.showAddTable = true
      this.editIndex = this.record[this.children.key].indexOf(itm)
      this.editRecord = { ...itm }
    },
    addItemTable() {
      this.$log("addItemTable ", this.editRecord)
      this.editRecord = {}
      this.editIndex = -1
      this.children.children.map(itm => {
        this.$log("ai ", itm.key)
        //this.editRecord[itm.key] = ""
        this.$set(this.editRecord, itm.key, "")
      })
      this.$log("addItemTable2 ", this.editRecord)
      this.showAddTable = true
    },
    saveDataTable() {
      this.$log("save rec ", this.editRecord)
      this.showAddTable = false
      if (this.editIndex >= 0) {
        this.record[this.children.key].splice(this.editIndex, 1, this.editRecord)
      } else {
        this.record[this.children.key].push(this.editRecord)
      }
    },
    searchLink() {
      //this.$refs.searchLink.open(this.children.data_relation.resource, this.children.data_relation.field)
      this.searchId = Date.now()
      EventBus.$emit("search-link", {
        resource: this.children.data_relation.resource,
        field: this.children.data_relation.field,
        id: this.searchId
      })
    },
    addRow(rec, child) {
      const clone = this.cloneObject(child, {})
      if (Object.entries(clone).length == 0) {
        rec.push("")
        this.render = Date.now()
      } else {
        rec.push({ ...clone })
        this.render = Date.now()
      }
    },
    delRow(rec, ind) {
      if (rec.length > 1) {
        rec.splice(ind, 1)
      } else {
        if (typeof rec[ind] == "string") {
          this.$set(rec, ind, "")
        } else {
          const clone = this.cloneObject(this.children, {})
          rec[ind] = { ...clone }
        }
        this.render = Date.now()
      }
    },
    cloneObject(child, clone) {
      child.children.map(itm => {
        if (itm.key.indexOf(".") > 0) {
          const itms = itm.key.split(".")
          let newc = {}
          for (let i = 1; i < itms.length - 1; i++) {
            newc = this.cloneKey(itms[i], newc)
          }
          if (itm.children) {
            newc[itms[itms.length - 1]] = []
            newc[itms[itms.length - 1]].push(this.cloneObject(itm, {}))
          } else {
            newc[itms[itms.length - 1]] = ""
          }
          if (clone[itms[0]]) {
            Object.assign(clone[itms[0]], newc)
          } else {
            clone[itms[0]] = newc
          }
        } else {
          if (itm.children) {
            clone[itm.key] = []
            // clone[itm.key].push(this.cloneObject(itm, {}))
            if (Object.entries(this.cloneObject(itm, {})).length == 0) {
              clone[itm.key].push("")
            } else {
              clone[itm.key].push(this.cloneObject(itm, {}))
            }
          } else {
            if (itm.key) {
              clone[itm.key] = ""
            }
          }
        }
      })
      // if (!clone["id"]) clone["id"] = Date.now()
      return clone
    },
    cloneKey(keys, clone) {
      return (clone[keys] = {})
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    },
    retType(rec) {
      if (typeof rec == "object") return true
      return false
    },
    getValue() {
      //this.$log("getValue ", this.record)
      //this.$log("getValue " + this.children.default + " - " + this.resolvePath(this.children.key, this.record))
      if (this.children.typeObject == "Password") {
        this.render = Date.now()
        if (this.resolvePath(this.children.key, this.record).length == 32) {
          return ""
        }
        return ""
      }
      if (this.children.key) {
        if (this.children.typeObject == "CheckBox" && this.resolvePath(this.children.key, this.record) == "") {
          this.setValue(this.children.key, this.record, false)
          // return false
        }
        //this.$log("getValue return " + this.children.key + " - " + this.resolvePath(this.children.key, this.record))
        if (this.children.default) {
          if (this.resolvePath(this.children.key, this.record) != null) {
            return this.resolvePath(this.children.key, this.record)
          } else {
            this.setValue(this.children.key, this.record, this.children.default)
            return this.children.default
          }
        } else {
          //this.$log("else")
          return this.resolvePath(this.children.key, this.record)
        }
      } else {
        return this.values[this.index]
      }
    },
    setValue(path, obj, value) {
      //this.$log("setvalue1 ", path)
      //this.$log("setvalue2 ", obj)
      //this.$log("setvalue3 ", value)
      if (this.children.typeObject == "Password") {
        value = md5(value)
      }
      if (this.children.typeObject == "CheckBox" && value == null) {
        value = false
      }
      if (this.children.key) {
        if (path.split(".").length == 1) {
          obj[path] = value
        } else {
          const ptarr = path.split(".")
          this.createPath(ptarr.slice(0, -1), obj)
          //this.$log("setvalue parrj ", ptarr)
          //this.$log("setvalue obj ", obj)
          const rec = this.resolvePath(ptarr.slice(0, -1).join("."), obj)
          //this.$log("setvalue4 ", rec)
          //this.$log("setvalue5 ", ptarr.slice(-1))
          rec[ptarr.slice(-1)] = value
        }
      } else {
        this.values[this.index] = value
      }
      this.render = Date.now()
    },
    createPath(path, obj) {
      if (!obj[path[0]]) {
        obj[path[0]] = {}
      }
      if (path.length > 1) {
        this.createPath(path.slice(1), obj[path[0]])
      }
    }
  }
}
</script>
<style scoped></style>
